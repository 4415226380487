export const cities_json =
    [
        {
            "city": "London",
            "lat": "51.5072",
            "lng": "-0.1275",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "London, City of",
            "capital": "primary",
            "population": "10979000",
            "population_proper": "8825001"
        },
        {
            "city": "Birmingham",
            "lat": "52.4800",
            "lng": "-1.9025",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "2897303",
            "population_proper": "1137100"
        },
        {
            "city": "Manchester",
            "lat": "53.4794",
            "lng": "-2.2453",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Manchester",
            "capital": "",
            "population": "2705000",
            "population_proper": "547627"
        },
        {
            "city": "Leeds",
            "lat": "53.7997",
            "lng": "-1.5492",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leeds",
            "capital": "",
            "population": "1901934",
            "population_proper": "789194"
        },
        {
            "city": "Newcastle",
            "lat": "55.0077",
            "lng": "-1.6578",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Newcastle upon Tyne",
            "capital": "",
            "population": "1650000",
            "population_proper": "879996"
        },
        {
            "city": "Birstall",
            "lat": "52.6736",
            "lng": "-1.1200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicester",
            "capital": "",
            "population": "1424216",
            "population_proper": "1424216"
        },
        {
            "city": "Glasgow",
            "lat": "55.8609",
            "lng": "-4.2514",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Glasgow City",
            "capital": "",
            "population": "985290",
            "population_proper": "633120"
        },
        {
            "city": "Liverpool",
            "lat": "53.4000",
            "lng": "-2.9833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Liverpool",
            "capital": "",
            "population": "864122",
            "population_proper": "513441"
        },
        {
            "city": "Portsmouth",
            "lat": "50.8058",
            "lng": "-1.0872",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Portsmouth",
            "capital": "",
            "population": "855679",
            "population_proper": "238800"
        },
        {
            "city": "Southampton",
            "lat": "50.9000",
            "lng": "-1.4000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Southampton",
            "capital": "",
            "population": "855569",
            "population_proper": "269781"
        },
        {
            "city": "Nottingham",
            "lat": "52.9500",
            "lng": "-1.1500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottingham",
            "capital": "",
            "population": "768638",
            "population_proper": "321500"
        },
        {
            "city": "Bristol",
            "lat": "51.4500",
            "lng": "-2.5833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bristol, City of",
            "capital": "",
            "population": "724000",
            "population_proper": "463400"
        },
        {
            "city": "Sheffield",
            "lat": "53.3833",
            "lng": "-1.4667",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sheffield",
            "capital": "",
            "population": "685368",
            "population_proper": "518090"
        },
        {
            "city": "Kingston upon Hull",
            "lat": "53.7444",
            "lng": "-0.3325",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kingston upon Hull, City of",
            "capital": "",
            "population": "573300",
            "population_proper": "256406"
        },
        {
            "city": "Leicester",
            "lat": "52.6333",
            "lng": "-1.1333",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicester",
            "capital": "",
            "population": "508916",
            "population_proper": "329839"
        },
        {
            "city": "Edinburgh",
            "lat": "55.9530",
            "lng": "-3.1890",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Edinburgh, City of",
            "capital": "",
            "population": "488050",
            "population_proper": "488050"
        },
        {
            "city": "Caerdydd",
            "lat": "51.4833",
            "lng": "-3.1833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cardiff",
            "capital": "",
            "population": "479000",
            "population_proper": "361469"
        },
        {
            "city": "Stoke-on-Trent",
            "lat": "53.0000",
            "lng": "-2.1833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stoke-on-Trent",
            "capital": "",
            "population": "372775",
            "population_proper": "249008"
        },
        {
            "city": "Coventry",
            "lat": "52.4081",
            "lng": "-1.5106",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Coventry",
            "capital": "",
            "population": "362690",
            "population_proper": "362690"
        },
        {
            "city": "Reading",
            "lat": "51.4542",
            "lng": "-0.9731",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Reading",
            "capital": "",
            "population": "337108",
            "population_proper": "230046"
        },
        {
            "city": "Belfast",
            "lat": "54.5964",
            "lng": "-5.9300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Belfast",
            "capital": "",
            "population": "333871",
            "population_proper": "333871"
        },
        {
            "city": "Derby",
            "lat": "52.9167",
            "lng": "-1.4667",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derby",
            "capital": "",
            "population": "270500",
            "population_proper": "248700"
        },
        {
            "city": "Plymouth",
            "lat": "50.3714",
            "lng": "-4.1422",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Plymouth",
            "capital": "",
            "population": "267918",
            "population_proper": "267918"
        },
        {
            "city": "Wolverhampton",
            "lat": "52.5833",
            "lng": "-2.1333",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wolverhampton",
            "capital": "",
            "population": "254406",
            "population_proper": "250970"
        },
        {
            "city": "Abertawe",
            "lat": "51.6167",
            "lng": "-3.9500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Swansea",
            "capital": "",
            "population": "245500",
            "population_proper": "245500"
        },
        {
            "city": "Milton Keynes",
            "lat": "52.0400",
            "lng": "-0.7600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Milton Keynes",
            "capital": "",
            "population": "229941",
            "population_proper": "207057"
        },
        {
            "city": "Aberdeen",
            "lat": "57.1500",
            "lng": "-2.1100",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Aberdeen City",
            "capital": "",
            "population": "214610",
            "population_proper": "214610"
        },
        {
            "city": "Norwich",
            "lat": "52.6300",
            "lng": "1.2970",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Norfolk",
            "capital": "",
            "population": "213166",
            "population_proper": "195971"
        },
        {
            "city": "Luton",
            "lat": "51.8783",
            "lng": "-0.4147",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Luton",
            "capital": "",
            "population": "213052",
            "population_proper": "213052"
        },
        {
            "city": "Islington",
            "lat": "51.5440",
            "lng": "-0.1027",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Islington",
            "capital": "",
            "population": "206125",
            "population_proper": "206125"
        },
        {
            "city": "Swindon",
            "lat": "51.5600",
            "lng": "-1.7800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Swindon",
            "capital": "",
            "population": "192599",
            "population_proper": "182441"
        },
        {
            "city": "Croydon",
            "lat": "51.3727",
            "lng": "-0.1099",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Croydon",
            "capital": "",
            "population": "192064",
            "population_proper": "192064"
        },
        {
            "city": "Basildon",
            "lat": "51.5761",
            "lng": "0.4886",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "185900",
            "population_proper": "185900"
        },
        {
            "city": "Bournemouth",
            "lat": "50.7200",
            "lng": "-1.8800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bournemouth",
            "capital": "",
            "population": "183491",
            "population_proper": "183491"
        },
        {
            "city": "Worthing",
            "lat": "50.8147",
            "lng": "-0.3714",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "183000",
            "population_proper": "109120"
        },
        {
            "city": "Ipswich",
            "lat": "52.0594",
            "lng": "1.1556",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Suffolk",
            "capital": "",
            "population": "180000",
            "population_proper": "180000"
        },
        {
            "city": "Middlesbrough",
            "lat": "54.5767",
            "lng": "-1.2355",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Redcar and Cleveland",
            "capital": "",
            "population": "174700",
            "population_proper": "140545"
        },
        {
            "city": "Sunderland",
            "lat": "54.9061",
            "lng": "-1.3811",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sunderland",
            "capital": "",
            "population": "174286",
            "population_proper": "174286"
        },
        {
            "city": "Ilford",
            "lat": "51.5588",
            "lng": "0.0855",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Redbridge",
            "capital": "",
            "population": "168168",
            "population_proper": "168168"
        },
        {
            "city": "Warrington",
            "lat": "53.3917",
            "lng": "-2.5972",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warrington",
            "capital": "",
            "population": "165456",
            "population_proper": "165456"
        },
        {
            "city": "Slough",
            "lat": "51.5100",
            "lng": "-0.5900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Slough",
            "capital": "",
            "population": "164455",
            "population_proper": "119070"
        },
        {
            "city": "Huddersfield",
            "lat": "53.6450",
            "lng": "-1.7798",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kirklees",
            "capital": "",
            "population": "162949",
            "population_proper": "162949"
        },
        {
            "city": "Oxford",
            "lat": "51.7519",
            "lng": "-1.2578",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "154600",
            "population_proper": "152000"
        },
        {
            "city": "York",
            "lat": "53.9583",
            "lng": "-1.0803",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "York",
            "capital": "",
            "population": "153717",
            "population_proper": "153717"
        },
        {
            "city": "Poole",
            "lat": "50.7167",
            "lng": "-1.9833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Poole",
            "capital": "",
            "population": "151500",
            "population_proper": "144800"
        },
        {
            "city": "Harrow",
            "lat": "51.5836",
            "lng": "-0.3464",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Harrow",
            "capital": "",
            "population": "149246",
            "population_proper": "149246"
        },
        {
            "city": "Dundee",
            "lat": "56.4620",
            "lng": "-2.9707",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dundee City",
            "capital": "",
            "population": "148270",
            "population_proper": "148270"
        },
        {
            "city": "Saint Albans",
            "lat": "51.7550",
            "lng": "-0.3360",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "147373",
            "population_proper": "82146"
        },
        {
            "city": "Telford",
            "lat": "52.6766",
            "lng": "-2.4469",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Telford and Wrekin",
            "capital": "",
            "population": "142723",
            "population_proper": "142723"
        },
        {
            "city": "Blackpool",
            "lat": "53.8142",
            "lng": "-3.0503",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Blackpool",
            "capital": "",
            "population": "139720",
            "population_proper": "139305"
        },
        {
            "city": "Brighton",
            "lat": "50.8429",
            "lng": "-0.1313",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Brighton and Hove",
            "capital": "",
            "population": "134293",
            "population_proper": "134293"
        },
        {
            "city": "Sale",
            "lat": "53.4240",
            "lng": "-2.3220",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Trafford",
            "capital": "",
            "population": "134022",
            "population_proper": "134022"
        },
        {
            "city": "Enfield",
            "lat": "51.6522",
            "lng": "-0.0808",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Enfield",
            "capital": "",
            "population": "132640",
            "population_proper": "132640"
        },
        {
            "city": "Tottenham",
            "lat": "51.5880",
            "lng": "-0.0720",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Haringey",
            "capital": "",
            "population": "129237",
            "population_proper": "129237"
        },
        {
            "city": "Bolton",
            "lat": "53.5780",
            "lng": "-2.4290",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bolton",
            "capital": "",
            "population": "128139",
            "population_proper": "128139"
        },
       /* {
            "city": "Newport",
            "lat": "51.5833",
            "lng": "-3.0000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Newport",
            "capital": "",
            "population": "128060",
            "population_proper": "128060"
        },*/
        {
            "city": "High Wycombe",
            "lat": "51.6287",
            "lng": "-0.7482",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Buckinghamshire",
            "capital": "",
            "population": "125257",
            "population_proper": "120256"
        },
        {
            "city": "Exeter",
            "lat": "50.7167",
            "lng": "-3.5333",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Devon",
            "capital": "",
            "population": "124180",
            "population_proper": "124180"
        },
        {
            "city": "Solihull",
            "lat": "52.4130",
            "lng": "-1.7780",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Solihull",
            "capital": "",
            "population": "123187",
            "population_proper": "123187"
        },
        {
            "city": "Romford",
            "lat": "51.5768",
            "lng": "0.1801",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Havering",
            "capital": "",
            "population": "122854",
            "population_proper": "122854"
        },
        {
            "city": "Preston",
            "lat": "53.7590",
            "lng": "-2.6990",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "122719",
            "population_proper": "122719"
        },
        {
            "city": "Gateshead",
            "lat": "54.9500",
            "lng": "-1.6000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Gateshead",
            "capital": "",
            "population": "120046",
            "population_proper": "120046"
        },
        {
            "city": "Blackburn",
            "lat": "53.7480",
            "lng": "-2.4820",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Blackburn with Darwen",
            "capital": "",
            "population": "117963",
            "population_proper": "117963"
        },
        {
            "city": "Cheltenham",
            "lat": "51.9000",
            "lng": "-2.0667",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Gloucestershire",
            "capital": "",
            "population": "116447",
            "population_proper": "116447"
        },
        {
            "city": "Basingstoke",
            "lat": "51.2667",
            "lng": "-1.0876",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "113776",
            "population_proper": "107355"
        },
        {
            "city": "Maidstone",
            "lat": "51.2720",
            "lng": "0.5290",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "113137",
            "population_proper": "107627"
        },
        {
            "city": "Colchester",
            "lat": "51.8917",
            "lng": "0.9030",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "111921",
            "population_proper": "111921"
        },
        {
            "city": "Chelmsford",
            "lat": "51.7361",
            "lng": "0.4798",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "111511",
            "population_proper": "111511"
        },
        {
            "city": "Wythenshawe",
            "lat": "53.3920",
            "lng": "-2.2640",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Manchester",
            "capital": "",
            "population": "110000",
            "population_proper": "110000"
        },
        {
            "city": "Doncaster",
            "lat": "53.5150",
            "lng": "-1.1330",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Doncaster",
            "capital": "",
            "population": "109805",
            "population_proper": "109805"
        },
        {
            "city": "Rotherham",
            "lat": "53.4300",
            "lng": "-1.3570",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rotherham",
            "capital": "",
            "population": "109691",
            "population_proper": "109691"
        },
        {
            "city": "Walthamstow",
            "lat": "51.5900",
            "lng": "-0.0000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Waltham Forest",
            "capital": "",
            "population": "109424",
            "population_proper": "109424"
        },
        {
            "city": "Rochdale",
            "lat": "53.6136",
            "lng": "-2.1610",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rochdale",
            "capital": "",
            "population": "107926",
            "population_proper": "107926"
        },
        {
            "city": "Bedford",
            "lat": "52.1350",
            "lng": "-0.4700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bedford",
            "capital": "",
            "population": "106940",
            "population_proper": "92407"
        },
        {
            "city": "Crawley",
            "lat": "51.1092",
            "lng": "-0.1872",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "106597",
            "population_proper": "106597"
        },
        {
            "city": "Mansfield",
            "lat": "53.1500",
            "lng": "-1.2000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "106556",
            "population_proper": "77551"
        },
        {
            "city": "Dagenham",
            "lat": "51.5397",
            "lng": "0.1422",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barking and Dagenham",
            "capital": "",
            "population": "106247",
            "population_proper": "106247"
        },
        {
            "city": "Stockport",
            "lat": "53.4083",
            "lng": "-2.1494",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stockport",
            "capital": "",
            "population": "105878",
            "population_proper": "105878"
        },
        {
            "city": "Darlington",
            "lat": "54.5270",
            "lng": "-1.5526",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Darlington",
            "capital": "",
            "population": "105564",
            "population_proper": "92363"
        },
        {
            "city": "Fyfield",
            "lat": "51.7390",
            "lng": "0.2722",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "104234",
            "population_proper": "104234"
        },
        {
            "city": "Gillingham",
            "lat": "51.3850",
            "lng": "0.5500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Medway",
            "capital": "",
            "population": "104157",
            "population_proper": "104157"
        },
        {
            "city": "Salford",
            "lat": "53.4830",
            "lng": "-2.2931",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Salford",
            "capital": "",
            "population": "103886",
            "population_proper": "103886"
        },
        {
            "city": "Eastbourne",
            "lat": "50.7700",
            "lng": "0.2800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Sussex",
            "capital": "",
            "population": "103745",
            "population_proper": "101547"
        },
        {
            "city": "Wigan",
            "lat": "53.5448",
            "lng": "-2.6318",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wigan",
            "capital": "",
            "population": "103608",
            "population_proper": "103608"
        },
        {
            "city": "Hounslow",
            "lat": "51.4668",
            "lng": "-0.3750",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hounslow",
            "capital": "",
            "population": "103337",
            "population_proper": "103337"
        },
        {
            "city": "Wembley",
            "lat": "51.5528",
            "lng": "-0.2979",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Brent",
            "capital": "",
            "population": "102856",
            "population_proper": "90045"
        },
        {
            "city": "Saint Helens",
            "lat": "53.4541",
            "lng": "-2.7461",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "St. Helens",
            "capital": "",
            "population": "102629",
            "population_proper": "102629"
        },
        {
            "city": "Worcester",
            "lat": "52.1920",
            "lng": "-2.2200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Worcestershire",
            "capital": "",
            "population": "101328",
            "population_proper": "101328"
        },
        {
            "city": "Wakefield",
            "lat": "53.6800",
            "lng": "-1.4900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wakefield",
            "capital": "",
            "population": "99251",
            "population_proper": "99251"
        },
        {
            "city": "Lincoln",
            "lat": "53.2344",
            "lng": "-0.5386",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lincolnshire",
            "capital": "",
            "population": "97541",
            "population_proper": "97541"
        },
        {
            "city": "Hemel Hempstead",
            "lat": "51.7526",
            "lng": "-0.4692",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "97500",
            "population_proper": "94932"
        },
        {
            "city": "Watford",
            "lat": "51.6550",
            "lng": "-0.3957",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "96800",
            "population_proper": "90301"
        },
        {
            "city": "Oldham",
            "lat": "53.5444",
            "lng": "-2.1169",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oldham",
            "capital": "",
            "population": "96555",
            "population_proper": "96555"
        },
        {
            "city": "Sutton Coldfield",
            "lat": "52.5630",
            "lng": "-1.8220",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "95107",
            "population_proper": "95107"
        },
        {
            "city": "Kettering",
            "lat": "52.3931",
            "lng": "-0.7229",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northamptonshire",
            "capital": "",
            "population": "93475",
            "population_proper": "56226"
        },
        {
            "city": "Hastings",
            "lat": "50.8500",
            "lng": "0.5700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Sussex",
            "capital": "",
            "population": "92855",
            "population_proper": "91053"
        },
        {
            "city": "Hartlepool",
            "lat": "54.6900",
            "lng": "-1.2100",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hartlepool",
            "capital": "",
            "population": "92000",
            "population_proper": "88855"
        },
        {
            "city": "Hove",
            "lat": "50.8352",
            "lng": "-0.1758",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Brighton and Hove",
            "capital": "",
            "population": "91900",
            "population_proper": "91900"
        },
        {
            "city": "Barnsley",
            "lat": "53.5537",
            "lng": "-1.4791",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barnsley",
            "capital": "",
            "population": "91297",
            "population_proper": "91297"
        },
        {
            "city": "Southport",
            "lat": "53.6475",
            "lng": "-3.0053",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sefton",
            "capital": "",
            "population": "90381",
            "population_proper": "90381"
        },
        {
            "city": "Bath",
            "lat": "51.3800",
            "lng": "-2.3600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bath and North East Somerset",
            "capital": "",
            "population": "88859",
            "population_proper": "88859"
        },
        {
            "city": "Birkenhead",
            "lat": "53.3930",
            "lng": "-3.0140",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wirral",
            "capital": "",
            "population": "88818",
            "population_proper": "88818"
        },
        {
            "city": "Grimsby",
            "lat": "53.5595",
            "lng": "-0.0680",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North East Lincolnshire",
            "capital": "",
            "population": "88243",
            "population_proper": "88243"
        },
        {
            "city": "Halifax",
            "lat": "53.7250",
            "lng": "-1.8630",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Calderdale",
            "capital": "",
            "population": "88134",
            "population_proper": "88134"
        },
        {
            "city": "Bromley",
            "lat": "51.4070",
            "lng": "0.0210",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bromley",
            "capital": "",
            "population": "87889",
            "population_proper": "87889"
        },
        {
            "city": "Fulham",
            "lat": "51.4828",
            "lng": "-0.1950",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hammersmith and Fulham",
            "capital": "",
            "population": "87161",
            "population_proper": "87161"
        },
        {
            "city": "Nuneaton",
            "lat": "52.5230",
            "lng": "-1.4680",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "86552",
            "population_proper": "86552"
        },
        {
            "city": "Ealing",
            "lat": "51.5175",
            "lng": "-0.2988",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "85014",
            "population_proper": "85014"
        },
        {
            "city": "Stockton-on-Tees",
            "lat": "54.5700",
            "lng": "-1.3200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stockton-on-Tees",
            "capital": "",
            "population": "85000",
            "population_proper": "82729"
        },
        {
            "city": "Woolwich",
            "lat": "51.4880",
            "lng": "0.0630",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Greenwich",
            "capital": "",
            "population": "84959",
            "population_proper": "84959"
        },
        {
            "city": "Bracknell",
            "lat": "51.4160",
            "lng": "-0.7490",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bracknell Forest",
            "capital": "",
            "population": "84469",
            "population_proper": "77256"
        },
        {
            "city": "Derry",
            "lat": "55.0004",
            "lng": "-7.3333",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derry and Strabane",
            "capital": "",
            "population": "83652",
            "population_proper": "81618"
        },
        {
            "city": "Hayes",
            "lat": "51.5127",
            "lng": "-0.4211",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hillingdon",
            "capital": "",
            "population": "83564",
            "population_proper": "83564"
        },
        {
            "city": "Edmonton",
            "lat": "51.6154",
            "lng": "-0.0708",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Enfield",
            "capital": "",
            "population": "82472",
            "population_proper": "82472"
        },
        {
            "city": "Scunthorpe",
            "lat": "53.5809",
            "lng": "-0.6502",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lincolnshire",
            "capital": "",
            "population": "82334",
            "population_proper": "79977"
        },
        {
            "city": "Redditch",
            "lat": "52.3069",
            "lng": "-1.9492",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Worcestershire",
            "capital": "",
            "population": "81919",
            "population_proper": "81919"
        },
        {
            "city": "Chester",
            "lat": "53.1900",
            "lng": "-2.8900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire West and Chester",
            "capital": "",
            "population": "79645",
            "population_proper": "79645"
        },
        {
            "city": "Dudley",
            "lat": "52.5080",
            "lng": "-2.0890",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dudley",
            "capital": "",
            "population": "79379",
            "population_proper": "79379"
        },
        {
            "city": "Bury",
            "lat": "53.5930",
            "lng": "-2.2980",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bury",
            "capital": "",
            "population": "78723",
            "population_proper": "78723"
        },
        {
            "city": "Brixton",
            "lat": "51.4630",
            "lng": "-0.1060",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lambeth",
            "capital": "",
            "population": "78536",
            "population_proper": "78536"
        },
        {
            "city": "West Bromwich",
            "lat": "52.5190",
            "lng": "-1.9950",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sandwell",
            "capital": "",
            "population": "77997",
            "population_proper": "77997"
        },
        {
            "city": "Paisley",
            "lat": "55.8466",
            "lng": "-4.4236",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Renfrewshire",
            "capital": "",
            "population": "77310",
            "population_proper": "76220"
        },
        {
            "city": "Guildford",
            "lat": "51.2365",
            "lng": "-0.5703",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "77057",
            "population_proper": "77057"
        },
        {
            "city": "Chatham",
            "lat": "51.3700",
            "lng": "0.5200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Medway",
            "capital": "",
            "population": "76792",
            "population_proper": "76792"
        },
        {
            "city": "Edgware",
            "lat": "51.6185",
            "lng": "-0.2729",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barnet",
            "capital": "",
            "population": "76506",
            "population_proper": "76506"
        },
        {
            "city": "East Ham",
            "lat": "51.5323",
            "lng": "0.0554",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Newham",
            "capital": "",
            "population": "76186",
            "population_proper": "76186"
        },
        {
            "city": "Weston-super-Mare",
            "lat": "51.3460",
            "lng": "-2.9770",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Somerset",
            "capital": "",
            "population": "76143",
            "population_proper": "76143"
        },
        {
            "city": "East Kilbride",
            "lat": "55.7645",
            "lng": "-4.1771",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Lanarkshire",
            "capital": "",
            "population": "75390",
            "population_proper": "74411"
        },
        {
            "city": "South Shields",
            "lat": "54.9950",
            "lng": "-1.4300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Tyneside",
            "capital": "",
            "population": "75337",
            "population_proper": "75337"
        },
        {
            "city": "Carlisle",
            "lat": "54.8910",
            "lng": "-2.9440",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cumbria",
            "capital": "",
            "population": "75306",
            "population_proper": "75306"
        },
        {
            "city": "Newcastle under Lyme",
            "lat": "53.0109",
            "lng": "-2.2278",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "75082",
            "population_proper": "75082"
        },
        {
            "city": "Harrogate",
            "lat": "53.9919",
            "lng": "-1.5378",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Yorkshire",
            "capital": "",
            "population": "75070",
            "population_proper": "73576"
        },
       /* {
            "city": "Ashford",
            "lat": "51.1465",
            "lng": "0.8676",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "74204",
            "population_proper": "67528"
        },*/
        {
            "city": "Gravesend",
            "lat": "51.4415",
            "lng": "0.3685",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "74000",
            "population_proper": "66000"
        },
        {
            "city": "Burnley",
            "lat": "53.7890",
            "lng": "-2.2480",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "73021",
            "population_proper": "73021"
        },
        {
            "city": "Burton upon Trent",
            "lat": "52.8019",
            "lng": "-1.6367",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "72299",
            "population_proper": "72299"
        },
        {
            "city": "Crewe",
            "lat": "53.0990",
            "lng": "-2.4400",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire East",
            "capital": "",
            "population": "71722",
            "population_proper": "71722"
        },
        {
            "city": "Shrewsbury",
            "lat": "52.7080",
            "lng": "-2.7540",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Shropshire",
            "capital": "",
            "population": "71715",
            "population_proper": "71715"
        },
        {
            "city": "Gosport",
            "lat": "50.7948",
            "lng": "-1.1243",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "71529",
            "population_proper": "71529"
        },
        {
            "city": "Lisburn",
            "lat": "54.5167",
            "lng": "-6.0333",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lisburn and Castlereagh",
            "capital": "",
            "population": "71465",
            "population_proper": "71465"
        },
        {
            "city": "Lowestoft",
            "lat": "52.4800",
            "lng": "1.7500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Suffolk",
            "capital": "",
            "population": "71010",
            "population_proper": "70945"
        },
        {
            "city": "Rugby",
            "lat": "52.3700",
            "lng": "-1.2600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "70628",
            "population_proper": "70627"
        },
        {
            "city": "Uxbridge",
            "lat": "51.5404",
            "lng": "-0.4778",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hillingdon",
            "capital": "",
            "population": "70560",
            "population_proper": "70560"
        },
        {
            "city": "Inverness",
            "lat": "57.4781",
            "lng": "-4.2233",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Highland",
            "capital": "",
            "population": "70000",
            "population_proper": "63780"
        },
        {
            "city": "Keighley",
            "lat": "53.8670",
            "lng": "-1.9110",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bradford",
            "capital": "",
            "population": "70000",
            "population_proper": "53331"
        },
        {
            "city": "Southall",
            "lat": "51.5121",
            "lng": "-0.3779",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "69857",
            "population_proper": "69857"
        },
        {
            "city": "Maidenhead",
            "lat": "51.5217",
            "lng": "-0.7177",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Windsor and Maidenhead",
            "capital": "",
            "population": "69309",
            "population_proper": "63580"
        },
        {
            "city": "Stafford",
            "lat": "52.8066",
            "lng": "-2.1171",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "68472",
            "population_proper": "68472"
        },
        {
            "city": "Wimbledon",
            "lat": "51.4220",
            "lng": "-0.2080",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Merton",
            "capital": "",
            "population": "68187",
            "population_proper": "68187"
        },
        {
            "city": "Walsall",
            "lat": "52.5800",
            "lng": "-1.9800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Walsall",
            "capital": "",
            "population": "67594",
            "population_proper": "67594"
        },
        {
            "city": "Tynemouth",
            "lat": "55.0170",
            "lng": "-1.4230",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Tyneside",
            "capital": "",
            "population": "67519",
            "population_proper": "67519"
        },
        {
            "city": "Washington",
            "lat": "54.9000",
            "lng": "-1.5200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sunderland",
            "capital": "",
            "population": "67085",
            "population_proper": "67085"
        },
        {
            "city": "Loughborough",
            "lat": "52.7705",
            "lng": "-1.2046",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicestershire",
            "capital": "",
            "population": "66611",
            "population_proper": "59932"
        },
        {
            "city": "Chingford",
            "lat": "51.6310",
            "lng": "0.0160",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Waltham Forest",
            "capital": "",
            "population": "66211",
            "population_proper": "66211"
        },
        {
            "city": "Thornton Heath",
            "lat": "51.4002",
            "lng": "-0.1086",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Croydon",
            "capital": "",
            "population": "65812",
            "population_proper": "65812"
        },
        {
            "city": "Finchley",
            "lat": "51.5990",
            "lng": "-0.1870",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barnet",
            "capital": "",
            "population": "65812",
            "population_proper": "65812"
        },
        {
            "city": "Wrecsam",
            "lat": "53.0460",
            "lng": "-2.9930",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wrexham",
            "capital": "",
            "population": "65692",
            "population_proper": "61603"
        },
        {
            "city": "Torquay",
            "lat": "50.4700",
            "lng": "-3.5300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Torbay",
            "capital": "",
            "population": "65245",
            "population_proper": "65245"
        },
        {
            "city": "Farnborough",
            "lat": "51.2900",
            "lng": "-0.7500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "65034",
            "population_proper": "65034"
        },
        {
            "city": "Kensington",
            "lat": "51.5000",
            "lng": "-0.1900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kensington and Chelsea",
            "capital": "",
            "population": "64681",
            "population_proper": "64681"
        },
        {
            "city": "Waterlooville",
            "lat": "50.8800",
            "lng": "-1.0300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "64350",
            "population_proper": "64350"
        },
        {
            "city": "Hornchurch",
            "lat": "51.5565",
            "lng": "0.2128",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Havering",
            "capital": "",
            "population": "63583",
            "population_proper": "63583"
        },
        {
            "city": "Mitcham",
            "lat": "51.4009",
            "lng": "-0.1517",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Merton",
            "capital": "",
            "population": "63393",
            "population_proper": "63393"
        },
        {
            "city": "Feltham",
            "lat": "51.4496",
            "lng": "-0.4089",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hounslow",
            "capital": "",
            "population": "63368",
            "population_proper": "63368"
        },
        {
            "city": "Stourbridge",
            "lat": "52.4575",
            "lng": "-2.1479",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dudley",
            "capital": "",
            "population": "63298",
            "population_proper": "63298"
        },
        {
            "city": "Rochester",
            "lat": "51.3750",
            "lng": "0.5000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Medway",
            "capital": "",
            "population": "62982",
            "population_proper": "62982"
        },
        {
            "city": "Dewsbury",
            "lat": "53.6910",
            "lng": "-1.6330",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kirklees",
            "capital": "",
            "population": "62945",
            "population_proper": "62945"
        },
        {
            "city": "Woking",
            "lat": "51.3162",
            "lng": "-0.5610",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "62796",
            "population_proper": "62796"
        },
        {
            "city": "Rhondda",
            "lat": "51.6159",
            "lng": "-3.4175",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rhondda Cynon Taff",
            "capital": "",
            "population": "62545",
            "population_proper": "62545"
        },
        {
            "city": "Sittingbourne",
            "lat": "51.3400",
            "lng": "0.7400",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "62500",
            "population_proper": "62500"
        },
        {
            "city": "Acton",
            "lat": "51.5135",
            "lng": "-0.2707",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "62480",
            "population_proper": "62480"
        },
        {
            "city": "Twickenham",
            "lat": "51.4490",
            "lng": "-0.3370",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Richmond upon Thames",
            "capital": "",
            "population": "62148",
            "population_proper": "62148"
        },
        {
            "city": "Runcorn",
            "lat": "53.3410",
            "lng": "-2.7290",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Halton",
            "capital": "",
            "population": "61789",
            "population_proper": "61789"
        },
        {
            "city": "Scarborough",
            "lat": "54.2773",
            "lng": "-0.4017",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Yorkshire",
            "capital": "",
            "population": "61749",
            "population_proper": "61749"
        },
        {
            "city": "Wandsworth",
            "lat": "51.4550",
            "lng": "-0.1920",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wandsworth",
            "capital": "",
            "population": "61594",
            "population_proper": "61594"
        },
        {
            "city": "Widnes",
            "lat": "53.3630",
            "lng": "-2.7280",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Halton",
            "capital": "",
            "population": "61464",
            "population_proper": "61464"
        },
        {
            "city": "Margate",
            "lat": "51.3850",
            "lng": "1.3838",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "61223",
            "population_proper": "61223"
        },
        {
            "city": "Bangor",
            "lat": "54.6600",
            "lng": "-5.6700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ards and North Down",
            "capital": "",
            "population": "61011",
            "population_proper": "61011"
        },
        {
            "city": "Morecambe",
            "lat": "54.0730",
            "lng": "-2.8700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "60768",
            "population_proper": "34768"
        },
        {
            "city": "Taunton",
            "lat": "51.0190",
            "lng": "-3.1000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Somerset",
            "capital": "",
            "population": "60479",
            "population_proper": "60479"
        },
        {
            "city": "Wallasey",
            "lat": "53.4158",
            "lng": "-3.0233",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wirral",
            "capital": "",
            "population": "60284",
            "population_proper": "60284"
        },
        {
            "city": "Royal Tunbridge Wells",
            "lat": "51.1320",
            "lng": "0.2630",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "59947",
            "population_proper": "57772"
        },
        {
            "city": "Barking",
            "lat": "51.5400",
            "lng": "0.0800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barking and Dagenham",
            "capital": "",
            "population": "59068",
            "population_proper": "59068"
        },
        {
            "city": "Aylesbury",
            "lat": "51.8168",
            "lng": "-0.8124",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Buckinghamshire",
            "capital": "",
            "population": "58740",
            "population_proper": "58740"
        },
        {
            "city": "Ruislip",
            "lat": "51.5760",
            "lng": "-0.4330",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hillingdon",
            "capital": "",
            "population": "58217",
            "population_proper": "58217"
        },
        {
            "city": "Halesowen",
            "lat": "52.4502",
            "lng": "-2.0509",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dudley",
            "capital": "",
            "population": "58135",
            "population_proper": "58135"
        },
        {
            "city": "Streatham",
            "lat": "51.4279",
            "lng": "-0.1235",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lambeth",
            "capital": "",
            "population": "58055",
            "population_proper": "58055"
        },
        {
            "city": "Livingston",
            "lat": "55.8834",
            "lng": "-3.5157",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Lothian",
            "capital": "",
            "population": "57030",
            "population_proper": "50826"
        },
        {
            "city": "Clacton-on-Sea",
            "lat": "51.7918",
            "lng": "1.1457",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "56874",
            "population_proper": "50548"
        },
        {
            "city": "Barrow in Furness",
            "lat": "54.1108",
            "lng": "-3.2261",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cumbria",
            "capital": "",
            "population": "56745",
            "population_proper": "56745"
        },
        {
            "city": "Hereford",
            "lat": "52.0565",
            "lng": "-2.7160",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Herefordshire",
            "capital": "",
            "population": "55955",
            "population_proper": "55955"
        },
        {
            "city": "Ellesmere Port",
            "lat": "53.2790",
            "lng": "-2.8970",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire West and Chester",
            "capital": "",
            "population": "55715",
            "population_proper": "55715"
        },
        {
            "city": "Kidderminster",
            "lat": "52.3885",
            "lng": "-2.2490",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Worcestershire",
            "capital": "",
            "population": "55530",
            "population_proper": "55530"
        },
        {
            "city": "Canterbury",
            "lat": "51.2800",
            "lng": "1.0800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "55240",
            "population_proper": "54880"
        },
        {
            "city": "Corby",
            "lat": "52.4914",
            "lng": "-0.6965",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northamptonshire",
            "capital": "",
            "population": "54927",
            "population_proper": "54927"
        },
        {
            "city": "Brentwood",
            "lat": "51.6204",
            "lng": "0.3050",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "54885",
            "population_proper": "49463"
        },
        {
            "city": "Hamilton",
            "lat": "55.7770",
            "lng": "-4.0390",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Lanarkshire",
            "capital": "",
            "population": "54230",
            "population_proper": "53188"
        },
        {
            "city": "Dunfermline",
            "lat": "56.0719",
            "lng": "-3.4393",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Fife",
            "capital": "",
            "population": "54050",
            "population_proper": "50380"
        },
        {
            "city": "Braintree",
            "lat": "51.8780",
            "lng": "0.5500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "53477",
            "population_proper": "53477"
        },
        {
            "city": "Weymouth",
            "lat": "50.6130",
            "lng": "-2.4570",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dorset",
            "capital": "",
            "population": "53068",
            "population_proper": "53068"
        },
        {
            "city": "Hendon",
            "lat": "51.5837",
            "lng": "-0.2252",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barnet",
            "capital": "",
            "population": "52972",
            "population_proper": "52972"
        },
        {
            "city": "Altrincham",
            "lat": "53.3838",
            "lng": "-2.3547",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Trafford",
            "capital": "",
            "population": "52419",
            "population_proper": "52419"
        },
        {
            "city": "Lancaster",
            "lat": "54.0470",
            "lng": "-2.8010",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "52234",
            "population_proper": "52234"
        },
        {
            "city": "Barri",
            "lat": "51.4050",
            "lng": "-3.2700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Vale of Glamorgan, The",
            "capital": "",
            "population": "51502",
            "population_proper": "51502"
        },
        {
            "city": "Macclesfield",
            "lat": "53.2581",
            "lng": "-2.1274",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire East",
            "capital": "",
            "population": "51482",
            "population_proper": "51482"
        },
        {
            "city": "Bootle",
            "lat": "53.4457",
            "lng": "-2.9891",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sefton",
            "capital": "",
            "population": "51394",
            "population_proper": "51394"
        },
        {
            "city": "Stratford",
            "lat": "51.5423",
            "lng": "-0.0026",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Newham",
            "capital": "",
            "population": "51387",
            "population_proper": "51387"
        },
        {
            "city": "Horsham",
            "lat": "51.0620",
            "lng": "-0.3250",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "50934",
            "population_proper": "50934"
        },
        {
            "city": "Cumbernauld",
            "lat": "55.9450",
            "lng": "-3.9940",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lanarkshire",
            "capital": "",
            "population": "50430",
            "population_proper": "50430"
        },
        {
            "city": "Rowley Regis",
            "lat": "52.4800",
            "lng": "-2.0600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sandwell",
            "capital": "",
            "population": "50257",
            "population_proper": "50257"
        },
        {
            "city": "Kirkcaldy",
            "lat": "56.1107",
            "lng": "-3.1674",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Fife",
            "capital": "",
            "population": "50180",
            "population_proper": "48108"
        },
        {
            "city": "Crosby",
            "lat": "53.4872",
            "lng": "-3.0343",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sefton",
            "capital": "",
            "population": "50044",
            "population_proper": "50044"
        },
        {
            "city": "Leith",
            "lat": "55.9800",
            "lng": "-3.1700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Edinburgh, City of",
            "capital": "",
            "population": "50030",
            "population_proper": "50030"
        },
        {
            "city": "Royal Leamington Spa",
            "lat": "52.2920",
            "lng": "-1.5370",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "49662",
            "population_proper": "49662"
        },
        {
            "city": "Llanelli",
            "lat": "51.6840",
            "lng": "-4.1630",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Carmarthenshire",
            "capital": "",
            "population": "49591",
            "population_proper": "37050"
        },
        {
            "city": "Batley",
            "lat": "53.7160",
            "lng": "-1.6270",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kirklees",
            "capital": "",
            "population": "49448",
            "population_proper": "49448"
        },
        {
            "city": "Pen-y-Bont ar Ogwr",
            "lat": "51.5072",
            "lng": "-3.5784",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bridgend",
            "capital": "",
            "population": "49404",
            "population_proper": "49404"
        },
        {
            "city": "Wellingborough",
            "lat": "52.2939",
            "lng": "-0.6964",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northamptonshire",
            "capital": "",
            "population": "49128",
            "population_proper": "49128"
        },
        {
            "city": "Sutton in Ashfield",
            "lat": "53.1250",
            "lng": "-1.2610",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "49023",
            "population_proper": "49023"
        },
        {
            "city": "Paignton",
            "lat": "50.4353",
            "lng": "-3.5625",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Devon",
            "capital": "",
            "population": "49021",
            "population_proper": "49021"
        },
        {
            "city": "Eltham",
            "lat": "51.4510",
            "lng": "0.0520",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Greenwich",
            "capital": "",
            "population": "48964",
            "population_proper": "48964"
        },
        {
            "city": "Cwmbran",
            "lat": "51.6530",
            "lng": "-3.0210",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Torfaen",
            "capital": "",
            "population": "48535",
            "population_proper": "48535"
        },
        {
            "city": "Christchurch",
            "lat": "50.7300",
            "lng": "-1.7800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dorset",
            "capital": "",
            "population": "48368",
            "population_proper": "48368"
        },
        {
            "city": "Morden",
            "lat": "51.4015",
            "lng": "-0.1949",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Merton",
            "capital": "",
            "population": "48233",
            "population_proper": "48233"
        },
        {
            "city": "Durham",
            "lat": "54.7761",
            "lng": "-1.5733",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Durham",
            "capital": "",
            "population": "48069",
            "population_proper": "48069"
        },
        {
            "city": "Barnet",
            "lat": "51.6444",
            "lng": "-0.1997",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Barnet",
            "capital": "",
            "population": "47359",
            "population_proper": "47359"
        },
        {
            "city": "West Bridgford",
            "lat": "52.9320",
            "lng": "-1.1270",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "47109",
            "population_proper": "47109"
        },
        {
            "city": "Perth",
            "lat": "56.3970",
            "lng": "-3.4370",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Perth and Kinross",
            "capital": "",
            "population": "46960",
            "population_proper": "43450"
        },
        {
            "city": "Stretford",
            "lat": "53.4466",
            "lng": "-2.3086",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Trafford",
            "capital": "",
            "population": "46910",
            "population_proper": "46910"
        },
        {
            "city": "Banbury",
            "lat": "52.0610",
            "lng": "-1.3360",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "46853",
            "population_proper": "46853"
        },
        {
            "city": "Beckenham",
            "lat": "51.4080",
            "lng": "-0.0220",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bromley",
            "capital": "",
            "population": "46844",
            "population_proper": "46844"
        },
        {
            "city": "Greenford",
            "lat": "51.5299",
            "lng": "-0.3488",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "46787",
            "population_proper": "46787"
        },
        {
            "city": "Wokingham",
            "lat": "51.4100",
            "lng": "-0.8400",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wokingham",
            "capital": "",
            "population": "46745",
            "population_proper": "46745"
        },
        {
            "city": "Folkestone",
            "lat": "51.0810",
            "lng": "1.1660",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "46698",
            "population_proper": "46698"
        },
        {
            "city": "Welwyn Garden City",
            "lat": "51.8062",
            "lng": "-0.1932",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "46619",
            "population_proper": "46619"
        },
        {
            "city": "Ayr",
            "lat": "55.4580",
            "lng": "-4.6290",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Ayrshire",
            "capital": "",
            "population": "46490",
            "population_proper": "46490"
        },
        {
            "city": "Kilmarnock",
            "lat": "55.6111",
            "lng": "-4.4957",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Ayrshire",
            "capital": "",
            "population": "46450",
            "population_proper": "46350"
        },
        {
            "city": "Havant",
            "lat": "50.8500",
            "lng": "-0.9800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "45826",
            "population_proper": "45826"
        },
        {
            "city": "Chippenham",
            "lat": "51.4585",
            "lng": "-2.1158",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wiltshire",
            "capital": "",
            "population": "45620",
            "population_proper": "45620"
        },
        {
            "city": "Erith",
            "lat": "51.4800",
            "lng": "0.1778",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bexley",
            "capital": "",
            "population": "45345",
            "population_proper": "45345"
        },
        {
            "city": "Hinckley",
            "lat": "52.5413",
            "lng": "-1.3725",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicestershire",
            "capital": "",
            "population": "45249",
            "population_proper": "45249"
        },
        {
            "city": "Ashton",
            "lat": "53.4897",
            "lng": "-2.0952",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Tameside",
            "capital": "",
            "population": "45198",
            "population_proper": "45198"
        },
        {
            "city": "Winchester",
            "lat": "51.0632",
            "lng": "-1.3080",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "45184",
            "population_proper": "35200"
        },
        {
            "city": "Surbiton",
            "lat": "51.3940",
            "lng": "-0.3070",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kingston upon Thames",
            "capital": "",
            "population": "45132",
            "population_proper": "45132"
        },
        {
            "city": "Yeovil",
            "lat": "50.9452",
            "lng": "-2.6370",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Somerset",
            "capital": "",
            "population": "45000",
            "population_proper": "45000"
        },
        {
            "city": "Catford",
            "lat": "51.4452",
            "lng": "-0.0207",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lewisham",
            "capital": "",
            "population": "44905",
            "population_proper": "44905"
        },
        {
            "city": "Grantham",
            "lat": "52.9180",
            "lng": "-0.6380",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lincolnshire",
            "capital": "",
            "population": "44580",
            "population_proper": "34592"
        },
        {
            "city": "Morley",
            "lat": "53.7492",
            "lng": "-1.6023",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leeds",
            "capital": "",
            "population": "44440",
            "population_proper": "44440"
        },
        {
            "city": "Cheshunt",
            "lat": "51.7020",
            "lng": "-0.0350",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "44000",
            "population_proper": "44000"
        },
        {
            "city": "Coatbridge",
            "lat": "55.8625",
            "lng": "-4.0266",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lanarkshire",
            "capital": "",
            "population": "43920",
            "population_proper": "43920"
        },
        {
            "city": "Wallsend",
            "lat": "54.9910",
            "lng": "-1.5340",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Tyneside",
            "capital": "",
            "population": "43826",
            "population_proper": "43826"
        },
        {
            "city": "Merthyr Tudful",
            "lat": "51.7430",
            "lng": "-3.3780",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Merthyr Tydfil",
            "capital": "",
            "population": "43820",
            "population_proper": "43820"
        },
        {
            "city": "Bexhill",
            "lat": "50.8424",
            "lng": "0.4676",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Sussex",
            "capital": "",
            "population": "43478",
            "population_proper": "43478"
        },
        {
            "city": "Sidcup",
            "lat": "51.4263",
            "lng": "0.1024",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bexley",
            "capital": "",
            "population": "43109",
            "population_proper": "43109"
        },
        {
            "city": "Kingston upon Thames",
            "lat": "51.4103",
            "lng": "-0.2995",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kingston upon Thames",
            "capital": "",
            "population": "43013",
            "population_proper": "43013"
        },
        {
            "city": "Middleton",
            "lat": "53.5550",
            "lng": "-2.1870",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rochdale",
            "capital": "",
            "population": "42972",
            "population_proper": "42972"
        },
        {
            "city": "King\u2019s Lynn",
            "lat": "52.7543",
            "lng": "0.3976",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Norfolk",
            "capital": "",
            "population": "42800",
            "population_proper": "42800"
        },
        {
            "city": "Fareham",
            "lat": "50.8500",
            "lng": "-1.1800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "42210",
            "population_proper": "42210"
        },
        {
            "city": "Greenock",
            "lat": "55.9500",
            "lng": "-4.7650",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Inverclyde",
            "capital": "",
            "population": "41990",
            "population_proper": "41990"
        },
        {
            "city": "Urmston",
            "lat": "53.4487",
            "lng": "-2.3747",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Trafford",
            "capital": "",
            "population": "41825",
            "population_proper": "41825"
        },
        {
            "city": "Worksop",
            "lat": "53.3042",
            "lng": "-1.1244",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "41820",
            "population_proper": "41820"
        },
        {
            "city": "Sutton",
            "lat": "51.3656",
            "lng": "-0.1963",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sutton",
            "capital": "",
            "population": "41483",
            "population_proper": "41483"
        },
        {
            "city": "Caerphilly",
            "lat": "51.5780",
            "lng": "-3.2180",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Caerphilly",
            "capital": "",
            "population": "41402",
            "population_proper": "41402"
        },
        {
            "city": "Bridgwater",
            "lat": "51.1280",
            "lng": "-2.9930",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Somerset",
            "capital": "",
            "population": "41276",
            "population_proper": "41276"
        },
        {
            "city": "Leigh",
            "lat": "53.4975",
            "lng": "-2.5150",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wigan",
            "capital": "",
            "population": "41275",
            "population_proper": "41275"
        },
        {
            "city": "Newbury",
            "lat": "51.4010",
            "lng": "-1.3230",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Berkshire",
            "capital": "",
            "population": "41075",
            "population_proper": "41075"
        },
        {
            "city": "Welling",
            "lat": "51.4594",
            "lng": "0.1097",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bexley",
            "capital": "",
            "population": "41000",
            "population_proper": "41000"
        },
        {
            "city": "Kingswood",
            "lat": "51.4600",
            "lng": "-2.5050",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Gloucestershire",
            "capital": "",
            "population": "40734",
            "population_proper": "40734"
        },
        {
            "city": "Bury Saint Edmunds",
            "lat": "52.2474",
            "lng": "0.7183",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Suffolk",
            "capital": "",
            "population": "40664",
            "population_proper": "40664"
        },
        {
            "city": "Kirkby",
            "lat": "53.4826",
            "lng": "-2.8920",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Knowsley",
            "capital": "",
            "population": "40472",
            "population_proper": "40472"
        },
        {
            "city": "Ramsgate",
            "lat": "51.3360",
            "lng": "1.4160",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "40408",
            "population_proper": "40408"
        },
        {
            "city": "Tonbridge",
            "lat": "51.1987",
            "lng": "0.2764",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "40356",
            "population_proper": "38657"
        },
        {
            "city": "Salisbury",
            "lat": "51.0740",
            "lng": "-1.7936",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wiltshire",
            "capital": "",
            "population": "40302",
            "population_proper": "40302"
        },
        {
            "city": "Castleford",
            "lat": "53.7160",
            "lng": "-1.3560",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wakefield",
            "capital": "",
            "population": "40210",
            "population_proper": "40210"
        },
        {
            "city": "Bishops Stortford",
            "lat": "51.8720",
            "lng": "0.1725",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "40089",
            "population_proper": "38202"
        },
        {
            "city": "Abingdon",
            "lat": "51.6670",
            "lng": "-1.2830",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "39809",
            "population_proper": "33130"
        },
        {
            "city": "Aberdare",
            "lat": "51.7130",
            "lng": "-3.4450",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rhondda Cynon Taff",
            "capital": "",
            "population": "39550",
            "population_proper": "39550"
        },
        {
            "city": "Farnham",
            "lat": "51.2150",
            "lng": "-0.7990",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "39488",
            "population_proper": "39488"
        },
        {
            "city": "Aldridge",
            "lat": "52.6060",
            "lng": "-1.9179",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Walsall",
            "capital": "",
            "population": "39348",
            "population_proper": "26988"
        },
        {
            "city": "Hatfield",
            "lat": "51.7620",
            "lng": "-0.2280",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "39201",
            "population_proper": "39201"
        },
        {
            "city": "Cleethorpes",
            "lat": "53.5533",
            "lng": "-0.0215",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North East Lincolnshire",
            "capital": "",
            "population": "38996",
            "population_proper": "38996"
        },
        {
            "city": "Skelmersdale",
            "lat": "53.5500",
            "lng": "-2.7760",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "38813",
            "population_proper": "38813"
        },
        {
            "city": "Tipton",
            "lat": "52.5259",
            "lng": "-2.0751",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dudley",
            "capital": "",
            "population": "38777",
            "population_proper": "38777"
        },
        {
            "city": "Eccles",
            "lat": "53.4824",
            "lng": "-2.3390",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Salford",
            "capital": "",
            "population": "38756",
            "population_proper": "38756"
        },
        {
            "city": "Great Yarmouth",
            "lat": "52.6060",
            "lng": "1.7290",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Norfolk",
            "capital": "",
            "population": "38693",
            "population_proper": "38693"
        },
        {
            "city": "Ilkeston",
            "lat": "52.9710",
            "lng": "-1.3090",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derbyshire",
            "capital": "",
            "population": "38640",
            "population_proper": "38640"
        },
        {
            "city": "Herne Bay",
            "lat": "51.3700",
            "lng": "1.1300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "38563",
            "population_proper": "38385"
        },
        {
            "city": "Andover",
            "lat": "51.2080",
            "lng": "-1.4800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "38290",
            "population_proper": "38290"
        },
        {
            "city": "Glenrothes",
            "lat": "56.1980",
            "lng": "-3.1780",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Fife",
            "capital": "",
            "population": "38240",
            "population_proper": "38240"
        },
        {
            "city": "Camberley",
            "lat": "51.3350",
            "lng": "-0.7420",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "38038",
            "population_proper": "38038"
        },
        {
            "city": "Stirling",
            "lat": "56.1166",
            "lng": "-3.9369",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stirling",
            "capital": "",
            "population": "37810",
            "population_proper": "37810"
        },
        {
            "city": "Arnold",
            "lat": "53.0050",
            "lng": "-1.1270",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "37768",
            "population_proper": "37768"
        },
        {
            "city": "Long Eaton",
            "lat": "52.8980",
            "lng": "-1.2710",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derbyshire",
            "capital": "",
            "population": "37760",
            "population_proper": "37760"
        },
        {
            "city": "Blyth",
            "lat": "55.1260",
            "lng": "-1.5140",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northumberland",
            "capital": "",
            "population": "37339",
            "population_proper": "37339"
        },
        {
            "city": "Port Talbot",
            "lat": "51.5906",
            "lng": "-3.7986",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Neath Port Talbot",
            "capital": "",
            "population": "37276",
            "population_proper": "37276"
        },
        {
            "city": "Bletchley",
            "lat": "51.9940",
            "lng": "-0.7320",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Milton Keynes",
            "capital": "",
            "population": "37114",
            "population_proper": "37114"
        },
        {
            "city": "Leighton Buzzard",
            "lat": "51.9165",
            "lng": "-0.6617",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Central Bedfordshire",
            "capital": "",
            "population": "37000",
            "population_proper": "37000"
        },
        {
            "city": "Fleet",
            "lat": "51.2834",
            "lng": "-0.8456",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "37000",
            "population_proper": "37000"
        },
        {
            "city": "Beeston",
            "lat": "52.9270",
            "lng": "-1.2150",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottinghamshire",
            "capital": "",
            "population": "37000",
            "population_proper": "37000"
        },
        {
            "city": "Small Heath",
            "lat": "52.4629",
            "lng": "-1.8542",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "36898",
            "population_proper": "36898"
        },
        {
            "city": "Houghton le Spring",
            "lat": "54.8410",
            "lng": "-1.4680",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sunderland",
            "capital": "",
            "population": "36746",
            "population_proper": "36746"
        },
        {
            "city": "Whitley Bay",
            "lat": "55.0456",
            "lng": "-1.4443",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Tyneside",
            "capital": "",
            "population": "36623",
            "population_proper": "36623"
        },
        {
            "city": "Airdrie",
            "lat": "55.8600",
            "lng": "-3.9800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lanarkshire",
            "capital": "",
            "population": "36620",
            "population_proper": "36620"
        },
        {
            "city": "Grays",
            "lat": "51.4750",
            "lng": "0.3300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Thurrock",
            "capital": "",
            "population": "36601",
            "population_proper": "36601"
        },
        {
            "city": "Denton",
            "lat": "53.4554",
            "lng": "-2.1122",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Tameside",
            "capital": "",
            "population": "36591",
            "population_proper": "36591"
        },
        {
            "city": "Aldershot",
            "lat": "51.2480",
            "lng": "-0.7580",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "36322",
            "population_proper": "36322"
        },
        {
            "city": "Redcar",
            "lat": "54.6180",
            "lng": "-1.0690",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Redcar and Cleveland",
            "capital": "",
            "population": "35692",
            "population_proper": "35692"
        },
        {
            "city": "Walkden",
            "lat": "53.5239",
            "lng": "-2.3991",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Salford",
            "capital": "",
            "population": "35616",
            "population_proper": "35616"
        },
        {
            "city": "Kenton",
            "lat": "51.5878",
            "lng": "-0.3086",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Brent",
            "capital": "",
            "population": "35600",
            "population_proper": "35600"
        },
        {
            "city": "Leyland",
            "lat": "53.6920",
            "lng": "-2.6970",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "35578",
            "population_proper": "35578"
        },
        {
            "city": "Woodley",
            "lat": "51.4530",
            "lng": "-0.9020",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wokingham",
            "capital": "",
            "population": "35470",
            "population_proper": "35470"
        },
        {
            "city": "Accrington",
            "lat": "53.7534",
            "lng": "-2.3638",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "35456",
            "population_proper": "35456"
        },
        {
            "city": "Pont-y-p\u0175l",
            "lat": "51.7030",
            "lng": "-3.0410",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Torfaen",
            "capital": "",
            "population": "35447",
            "population_proper": "35447"
        },
        {
            "city": "Bridlington",
            "lat": "54.0819",
            "lng": "-0.1923",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Riding of Yorkshire",
            "capital": "",
            "population": "35369",
            "population_proper": "35369"
        },
        {
            "city": "Falkirk",
            "lat": "56.0011",
            "lng": "-3.7835",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Falkirk",
            "capital": "",
            "population": "35168",
            "population_proper": "35168"
        },
        {
            "city": "Billingham",
            "lat": "54.6100",
            "lng": "-1.2700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stockton-on-Tees",
            "capital": "",
            "population": "35165",
            "population_proper": "35165"
        },
        {
            "city": "Boston",
            "lat": "52.9740",
            "lng": "-0.0214",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lincolnshire",
            "capital": "",
            "population": "35124",
            "population_proper": "35124"
        },
        {
            "city": "Dunstable",
            "lat": "51.8860",
            "lng": "-0.5210",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Central Bedfordshire",
            "capital": "",
            "population": "35000",
            "population_proper": "35000"
        },
        {
            "city": "Ewell",
            "lat": "51.3500",
            "lng": "-0.2490",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "34872",
            "population_proper": "34872"
        },
        {
            "city": "Chorley",
            "lat": "53.6530",
            "lng": "-2.6320",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "34667",
            "population_proper": "34667"
        },
        {
            "city": "Spalding",
            "lat": "52.7858",
            "lng": "-0.1529",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lincolnshire",
            "capital": "",
            "population": "34613",
            "population_proper": "34613"
        },
        {
            "city": "Exmouth",
            "lat": "50.6200",
            "lng": "-3.4130",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Devon",
            "capital": "",
            "population": "34432",
            "population_proper": "34432"
        },
        {
            "city": "North Shields",
            "lat": "55.0097",
            "lng": "-1.4448",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Tyneside",
            "capital": "",
            "population": "34423",
            "population_proper": "34423"
        },
        {
            "city": "Colwyn Bay",
            "lat": "53.2900",
            "lng": "-3.7000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Conwy",
            "capital": "",
            "population": "34284",
            "population_proper": "34284"
        },
        {
            "city": "Irvine",
            "lat": "55.6201",
            "lng": "-4.6614",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Ayrshire",
            "capital": "",
            "population": "34010",
            "population_proper": "33698"
        },
        {
            "city": "Hyde",
            "lat": "53.4474",
            "lng": "-2.0820",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Tameside",
            "capital": "",
            "population": "34003",
            "population_proper": "34003"
        },
        {
            "city": "Wisbech",
            "lat": "52.6640",
            "lng": "0.1600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cambridgeshire",
            "capital": "",
            "population": "33933",
            "population_proper": "33933"
        },
        {
            "city": "Lichfield",
            "lat": "52.6835",
            "lng": "-1.8265",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "33816",
            "population_proper": "33816"
        },
        {
            "city": "Wickford",
            "lat": "51.6114",
            "lng": "0.5207",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "33486",
            "population_proper": "33486"
        },
        {
            "city": "Hitchin",
            "lat": "51.9470",
            "lng": "-0.2830",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "33352",
            "population_proper": "33352"
        },
        {
            "city": "Dumfries",
            "lat": "55.0700",
            "lng": "-3.6030",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dumfries and Galloway",
            "capital": "",
            "population": "33320",
            "population_proper": "32914"
        },
        {
            "city": "Letchworth",
            "lat": "51.9780",
            "lng": "-0.2300",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "33249",
            "population_proper": "33249"
        },
        {
            "city": "Huyton",
            "lat": "53.4100",
            "lng": "-2.8430",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Knowsley",
            "capital": "",
            "population": "33193",
            "population_proper": "33193"
        },
        {
            "city": "Strood",
            "lat": "51.3930",
            "lng": "0.4780",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Medway",
            "capital": "",
            "population": "33182",
            "population_proper": "33182"
        },
        {
            "city": "Trowbridge",
            "lat": "51.3200",
            "lng": "-2.2100",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wiltshire",
            "capital": "",
            "population": "33108",
            "population_proper": "33108"
        },
        {
            "city": "Glossop",
            "lat": "53.4430",
            "lng": "-1.9490",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derbyshire",
            "capital": "",
            "population": "33000",
            "population_proper": "33000"
        },
        {
            "city": "Pontypridd",
            "lat": "51.6020",
            "lng": "-3.3420",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rhondda Cynon Taff",
            "capital": "",
            "population": "32694",
            "population_proper": "32694"
        },
        {
            "city": "Stroud",
            "lat": "51.7440",
            "lng": "-2.2150",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Gloucestershire",
            "capital": "",
            "population": "32670",
            "population_proper": "32670"
        },
        {
            "city": "Bicester",
            "lat": "51.9000",
            "lng": "-1.1500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "32642",
            "population_proper": "32642"
        },
        {
            "city": "Winsford",
            "lat": "53.1940",
            "lng": "-2.5200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire West and Chester",
            "capital": "",
            "population": "32610",
            "population_proper": "32610"
        },
        {
            "city": "Windsor",
            "lat": "51.4791",
            "lng": "-0.6095",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Windsor and Maidenhead",
            "capital": "",
            "population": "32608",
            "population_proper": "32184"
        },
        {
            "city": "Motherwell",
            "lat": "55.7839",
            "lng": "-3.9852",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lanarkshire",
            "capital": "",
            "population": "32480",
            "population_proper": "32480"
        },
        {
            "city": "Brighouse",
            "lat": "53.7070",
            "lng": "-1.7940",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Calderdale",
            "capital": "",
            "population": "32360",
            "population_proper": "32360"
        },
        {
            "city": "Wigston Magna",
            "lat": "52.5812",
            "lng": "-1.0930",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicestershire",
            "capital": "",
            "population": "32321",
            "population_proper": "32321"
        },
        {
            "city": "Swadlincote",
            "lat": "52.7740",
            "lng": "-1.5570",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Derbyshire",
            "capital": "",
            "population": "32219",
            "population_proper": "32219"
        },
        {
            "city": "Rayleigh",
            "lat": "51.5864",
            "lng": "0.6049",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "32150",
            "population_proper": "32150"
        },
        {
            "city": "Whitstable",
            "lat": "51.3610",
            "lng": "1.0260",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "32100",
            "population_proper": "30195"
        },
        {
            "city": "Ecclesfield",
            "lat": "53.4429",
            "lng": "-1.4698",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sheffield",
            "capital": "",
            "population": "32073",
            "population_proper": "32073"
        },
        {
            "city": "Ryde",
            "lat": "50.7271",
            "lng": "-1.1618",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Isle of Wight",
            "capital": "",
            "population": "32072",
            "population_proper": "32072"
        },
        {
            "city": "Earley",
            "lat": "51.4330",
            "lng": "-0.9330",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wokingham",
            "capital": "",
            "population": "32036",
            "population_proper": "32036"
        },
        {
            "city": "Borehamwood",
            "lat": "51.6578",
            "lng": "-0.2723",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "31955",
            "population_proper": "31955"
        },
        {
            "city": "Bexleyheath",
            "lat": "51.4590",
            "lng": "0.1380",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bexley",
            "capital": "",
            "population": "31929",
            "population_proper": "31929"
        },
        {
            "city": "Prestwich",
            "lat": "53.5333",
            "lng": "-2.2833",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bury",
            "capital": "",
            "population": "31693",
            "population_proper": "31693"
        },
        {
            "city": "Darwen",
            "lat": "53.6980",
            "lng": "-2.4610",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Blackburn with Darwen",
            "capital": "",
            "population": "31570",
            "population_proper": "31570"
        },
        {
            "city": "Epsom",
            "lat": "51.3360",
            "lng": "-0.2670",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "31489",
            "population_proper": "31489"
        },
        {
            "city": "Warwick",
            "lat": "52.2800",
            "lng": "-1.5900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "31345",
            "population_proper": "31345"
        },
        {
            "city": "Pinner",
            "lat": "51.5932",
            "lng": "-0.3894",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Harrow",
            "capital": "",
            "population": "31130",
            "population_proper": "31130"
        },
        {
            "city": "Rutherglen",
            "lat": "55.8286",
            "lng": "-4.2139",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Glasgow City",
            "capital": "",
            "population": "31120",
            "population_proper": "31120"
        },
        {
            "city": "Loughton",
            "lat": "51.6494",
            "lng": "0.0735",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "31106",
            "population_proper": "31106"
        },
        {
            "city": "Dover",
            "lat": "51.1295",
            "lng": "1.3089",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "31022",
            "population_proper": "31022"
        },
        {
            "city": "Pontefract",
            "lat": "53.6910",
            "lng": "-1.3120",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wakefield",
            "capital": "",
            "population": "30881",
            "population_proper": "30881"
        },
        {
            "city": "Saint Neots",
            "lat": "52.2280",
            "lng": "-0.2700",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cambridgeshire",
            "capital": "",
            "population": "30811",
            "population_proper": "30811"
        },
        {
            "city": "Bedworth",
            "lat": "52.4750",
            "lng": "-1.4770",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "30648",
            "population_proper": "30648"
        },
        {
            "city": "Burgess Hill",
            "lat": "50.9535",
            "lng": "-0.1262",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "30635",
            "population_proper": "30635"
        },
        {
            "city": "Northolt",
            "lat": "51.5470",
            "lng": "-0.3620",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "30304",
            "population_proper": "30304"
        },
        {
            "city": "Farnworth",
            "lat": "53.5452",
            "lng": "-2.3999",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bolton",
            "capital": "",
            "population": "30271",
            "population_proper": "30271"
        },
        {
            "city": "Wishaw",
            "lat": "55.7739",
            "lng": "-3.9189",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Lanarkshire",
            "capital": "",
            "population": "30250",
            "population_proper": "30250"
        },
        {
            "city": "Harpenden",
            "lat": "51.8175",
            "lng": "-0.3524",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "30240",
            "population_proper": "30240"
        },
        {
            "city": "Deal",
            "lat": "51.2226",
            "lng": "1.4006",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "30085",
            "population_proper": "30085"
        },
        {
            "city": "Radcliffe",
            "lat": "53.5615",
            "lng": "-2.3268",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Bury",
            "capital": "",
            "population": "29950",
            "population_proper": "29950"
        },
        {
            "city": "Carshalton",
            "lat": "51.3652",
            "lng": "-0.1676",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sutton",
            "capital": "",
            "population": "29917",
            "population_proper": "29917"
        },
        {
            "city": "Plympton",
            "lat": "50.3860",
            "lng": "-4.0510",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Plymouth",
            "capital": "",
            "population": "29899",
            "population_proper": "29899"
        },
        {
            "city": "Bulwell",
            "lat": "53.0010",
            "lng": "-1.1970",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottingham",
            "capital": "",
            "population": "29771",
            "population_proper": "29771"
        },
        {
            "city": "Cambuslang",
            "lat": "55.8190",
            "lng": "-4.1671",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Lanarkshire",
            "capital": "",
            "population": "29690",
            "population_proper": "29690"
        },
        {
            "city": "Sevenoaks",
            "lat": "51.2781",
            "lng": "0.1874",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "29506",
            "population_proper": "29506"
        },
        {
            "city": "Northfleet",
            "lat": "51.4400",
            "lng": "0.3400",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Kent",
            "capital": "",
            "population": "29474",
            "population_proper": "29474"
        },
        {
            "city": "Cramlington",
            "lat": "55.0820",
            "lng": "-1.5850",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northumberland",
            "capital": "",
            "population": "29405",
            "population_proper": "29405"
        },
        {
            "city": "Rushden",
            "lat": "52.2880",
            "lng": "-0.6010",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northamptonshire",
            "capital": "",
            "population": "29272",
            "population_proper": "29272"
        },
        {
            "city": "Bromsgrove",
            "lat": "52.3353",
            "lng": "-2.0579",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Worcestershire",
            "capital": "",
            "population": "29237",
            "population_proper": "29237"
        },
        {
            "city": "Nelson",
            "lat": "53.8346",
            "lng": "-2.2180",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "29135",
            "population_proper": "29135"
        },
        {
            "city": "Beverley",
            "lat": "53.8450",
            "lng": "-0.4270",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Riding of Yorkshire",
            "capital": "",
            "population": "29110",
            "population_proper": "29110"
        },
        {
            "city": "Cannock",
            "lat": "52.6910",
            "lng": "-2.0270",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "29018",
            "population_proper": "29018"
        },
        {
            "city": "Hanwell",
            "lat": "51.5189",
            "lng": "-0.3376",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Ealing",
            "capital": "",
            "population": "28768",
            "population_proper": "28768"
        },
        {
            "city": "Stanford le Hope",
            "lat": "51.5140",
            "lng": "0.4244",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Thurrock",
            "capital": "",
            "population": "28765",
            "population_proper": "28765"
        },
        {
            "city": "Kendal",
            "lat": "54.3260",
            "lng": "-2.7450",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cumbria",
            "capital": "",
            "population": "28586",
            "population_proper": "28586"
        },
        {
            "city": "Willenhall",
            "lat": "52.5798",
            "lng": "-2.0605",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Walsall",
            "capital": "",
            "population": "28480",
            "population_proper": "28480"
        },
        {
            "city": "Wood Green",
            "lat": "51.5981",
            "lng": "-0.1149",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Haringey",
            "capital": "",
            "population": "28453",
            "population_proper": "28453"
        },
        {
            "city": "Heywood",
            "lat": "53.5900",
            "lng": "-2.2190",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Rochdale",
            "capital": "",
            "population": "28205",
            "population_proper": "28205"
        },
        {
            "city": "Reddish",
            "lat": "53.4383",
            "lng": "-2.1613",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stockport",
            "capital": "",
            "population": "28052",
            "population_proper": "28052"
        },
        {
            "city": "Billericay",
            "lat": "51.6280",
            "lng": "0.4184",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "27998",
            "population_proper": "27998"
        },
        {
            "city": "Darlaston",
            "lat": "52.5708",
            "lng": "-2.0457",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Walsall",
            "capital": "",
            "population": "27821",
            "population_proper": "27821"
        },
        {
            "city": "Littlehampton",
            "lat": "50.8094",
            "lng": "-0.5409",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "27795",
            "population_proper": "27795"
        },
        {
            "city": "Ashington",
            "lat": "55.1810",
            "lng": "-1.5680",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northumberland",
            "capital": "",
            "population": "27764",
            "population_proper": "27764"
        },
        {
            "city": "Newton Mearns",
            "lat": "55.7716",
            "lng": "-4.3347",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "East Renfrewshire",
            "capital": "",
            "population": "27660",
            "population_proper": "27660"
        },
        {
            "city": "Yate",
            "lat": "51.5402",
            "lng": "-2.4110",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Gloucestershire",
            "capital": "",
            "population": "27603",
            "population_proper": "27603"
        },
        {
            "city": "Jarrow",
            "lat": "54.9797",
            "lng": "-1.4804",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "South Tyneside",
            "capital": "",
            "population": "27526",
            "population_proper": "27526"
        },
        {
            "city": "Witney",
            "lat": "51.7800",
            "lng": "-1.4900",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "27522",
            "population_proper": "27522"
        },
        {
            "city": "Stratford-upon-Avon",
            "lat": "52.1928",
            "lng": "-1.7064",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Warwickshire",
            "capital": "",
            "population": "27445",
            "population_proper": "27445"
        },
        {
            "city": "Shotley Bridge",
            "lat": "54.8700",
            "lng": "-1.8600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Durham",
            "capital": "",
            "population": "27394",
            "population_proper": "27394"
        },
        {
            "city": "Ashford",
            "lat": "51.4340",
            "lng": "-0.4640",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Surrey",
            "capital": "",
            "population": "27382",
            "population_proper": "27382"
        },
        {
            "city": "Ormskirk",
            "lat": "53.5665",
            "lng": "-2.8869",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "27343",
            "population_proper": "27343"
        },
        {
            "city": "Longton",
            "lat": "52.9877",
            "lng": "-2.1327",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stoke-on-Trent",
            "capital": "",
            "population": "27214",
            "population_proper": "27214"
        },
        {
            "city": "Melton Mowbray",
            "lat": "52.7661",
            "lng": "-0.8860",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Leicestershire",
            "capital": "",
            "population": "27158",
            "population_proper": "27158"
        },
        {
            "city": "Haverhill",
            "lat": "52.0800",
            "lng": "0.4400",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Suffolk",
            "capital": "",
            "population": "27041",
            "population_proper": "27041"
        },
        {
            "city": "Didcot",
            "lat": "51.6060",
            "lng": "-1.2410",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Oxfordshire",
            "capital": "",
            "population": "26920",
            "population_proper": "26920"
        },
        {
            "city": "Clifton",
            "lat": "52.9040",
            "lng": "-1.1770",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Nottingham",
            "capital": "",
            "population": "26835",
            "population_proper": "26835"
        },
        {
            "city": "Chichester",
            "lat": "50.8365",
            "lng": "-0.7792",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "26795",
            "population_proper": "26795"
        },
        {
            "city": "Hertford",
            "lat": "51.7950",
            "lng": "-0.0780",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "26783",
            "population_proper": "26783"
        },
        {
            "city": "Highbury",
            "lat": "51.5520",
            "lng": "-0.0970",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Islington",
            "capital": "",
            "population": "26664",
            "population_proper": "26664"
        },
        {
            "city": "Newton Aycliffe",
            "lat": "54.6200",
            "lng": "-1.5800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Durham",
            "capital": "",
            "population": "26633",
            "population_proper": "26633"
        },
        {
            "city": "Fern Down",
            "lat": "50.8100",
            "lng": "-1.9000",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Dorset",
            "capital": "",
            "population": "26559",
            "population_proper": "17650"
        },
        {
            "city": "Congleton",
            "lat": "53.1620",
            "lng": "-2.2170",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cheshire East",
            "capital": "",
            "population": "26482",
            "population_proper": "26482"
        },
        {
            "city": "Cheadle Hulme",
            "lat": "53.3761",
            "lng": "-2.1897",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Stockport",
            "capital": "",
            "population": "26479",
            "population_proper": "26479"
        },
        {
            "city": "East Grinstead",
            "lat": "51.1290",
            "lng": "-0.0070",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Sussex",
            "capital": "",
            "population": "26383",
            "population_proper": "26383"
        },
        {
            "city": "Thatcham",
            "lat": "51.4050",
            "lng": "-1.2640",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Berkshire",
            "capital": "",
            "population": "26217",
            "population_proper": "26217"
        },
        {
            "city": "Frome",
            "lat": "51.2279",
            "lng": "-2.3215",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Somerset",
            "capital": "",
            "population": "26203",
            "population_proper": "26203"
        },
        {
            "city": "Burntwood",
            "lat": "52.6831",
            "lng": "-1.9200",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "26049",
            "population_proper": "26049"
        },
        {
            "city": "Clydebank",
            "lat": "55.8997",
            "lng": "-4.4006",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "West Dunbartonshire",
            "capital": "",
            "population": "25970",
            "population_proper": "25970"
        },
        {
            "city": "Fleetwood",
            "lat": "53.9230",
            "lng": "-3.0150",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Lancashire",
            "capital": "",
            "population": "25939",
            "population_proper": "25939"
        },
        {
            "city": "Bournville",
            "lat": "52.4299",
            "lng": "-1.9355",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "25938",
            "population_proper": "25938"
        },
        {
            "city": "Shenley Brook End",
            "lat": "52.0090",
            "lng": "-0.7890",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Milton Keynes",
            "capital": "",
            "population": "25828",
            "population_proper": "25828"
        },
        {
            "city": "Bushey",
            "lat": "51.6429",
            "lng": "-0.3604",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hertfordshire",
            "capital": "",
            "population": "25763",
            "population_proper": "25763"
        },
        {
            "city": "New Milton",
            "lat": "50.7600",
            "lng": "-1.6500",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Hampshire",
            "capital": "",
            "population": "25717",
            "population_proper": "25717"
        },
        {
            "city": "Northfield",
            "lat": "52.4080",
            "lng": "-1.9630",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "25707",
            "population_proper": "25707"
        },
        {
            "city": "Coulsdon",
            "lat": "51.3211",
            "lng": "-0.1386",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Croydon",
            "capital": "",
            "population": "25695",
            "population_proper": "25695"
        },
        {
            "city": "Bilston",
            "lat": "52.5660",
            "lng": "-2.0728",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wolverhampton",
            "capital": "",
            "population": "25576",
            "population_proper": "25576"
        },
        {
            "city": "Newton Abbot",
            "lat": "50.5290",
            "lng": "-3.6100",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Devon",
            "capital": "",
            "population": "25556",
            "population_proper": "23580"
        },
        {
            "city": "Wellington",
            "lat": "52.7001",
            "lng": "-2.5157",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Telford and Wrekin",
            "capital": "",
            "population": "25554",
            "population_proper": "25554"
        },
        {
            "city": "Newport",
            "lat": "50.7010",
            "lng": "-1.2883",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Isle of Wight",
            "capital": "",
            "population": "25496",
            "population_proper": "23957"
        },
        {
            "city": "Oldbury",
            "lat": "52.5050",
            "lng": "-2.0159",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Sandwell",
            "capital": "",
            "population": "25488",
            "population_proper": "25488"
        },
        {
            "city": "Bishop Auckland",
            "lat": "54.6566",
            "lng": "-1.6768",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Durham",
            "capital": "",
            "population": "25455",
            "population_proper": "25455"
        },
        {
            "city": "Longbridge",
            "lat": "52.3950",
            "lng": "-1.9790",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Birmingham",
            "capital": "",
            "population": "25410",
            "population_proper": "25410"
        },
        {
            "city": "Bloxwich",
            "lat": "52.6140",
            "lng": "-2.0040",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Walsall",
            "capital": "",
            "population": "25401",
            "population_proper": "25401"
        },
        {
            "city": "Upminster",
            "lat": "51.5557",
            "lng": "0.2512",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Havering",
            "capital": "",
            "population": "25361",
            "population_proper": "25361"
        },
        {
            "city": "Witham",
            "lat": "51.7978",
            "lng": "0.6373",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "25353",
            "population_proper": "25353"
        },
        {
            "city": "Workington",
            "lat": "54.6365",
            "lng": "-3.5549",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Cumbria",
            "capital": "",
            "population": "25207",
            "population_proper": "25207"
        },
        {
            "city": "Kingswinford",
            "lat": "52.4981",
            "lng": "-2.1657",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Staffordshire",
            "capital": "",
            "population": "25191",
            "population_proper": "25191"
        },
        {
            "city": "Rhyl",
            "lat": "53.3210",
            "lng": "-3.4800",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Denbighshire",
            "capital": "",
            "population": "25149",
            "population_proper": "25149"
        },
        {
            "city": "Daventry",
            "lat": "52.2600",
            "lng": "-1.1600",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Northamptonshire",
            "capital": "",
            "population": "25026",
            "population_proper": "25026"
        },
        {
            "city": "Hindley",
            "lat": "53.5355",
            "lng": "-2.5658",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Wigan",
            "capital": "",
            "population": "25001",
            "population_proper": "25001"
        },
        {
            "city": "Rainham",
            "lat": "51.3600",
            "lng": "0.6100",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Medway",
            "capital": "",
            "population": "25000",
            "population_proper": "25000"
        },
        {
            "city": "Portishead",
            "lat": "51.4840",
            "lng": "-2.7626",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "North Somerset",
            "capital": "",
            "population": "25000",
            "population_proper": "25000"
        },
        {
            "city": "Pitsea",
            "lat": "51.5690",
            "lng": "0.5040",
            "country": "United Kingdom",
            "iso2": "GB",
            "admin_name": "Essex",
            "capital": "",
            "population": "25000",
            "population_proper": "25000"
        }
    ]


export const cities = cities_json.map((x) => ({
    label: x.city,
    value: x.city,
}));
