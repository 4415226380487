var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-wrapper auth-v2" },
    [
      _vm.is_submitted
        ? _c(
            "b-row",
            { staticClass: "auth-inner m-0" },
            [
              _c("b-link", { staticClass: "brand-logo" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo/hyre-logo.png"),
                    alt: ""
                  }
                })
              ]),
              _c(
                "b-col",
                {
                  staticClass: "d-none d-lg-flex align-items-center",
                  staticStyle: { "background-color": "#F0F8FC" },
                  attrs: { lg: "6", xl: "7" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-lg-flex align-items-center justify-content-center px-5"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            fill: "none",
                            height: "900",
                            viewBox: "0 0 812 900",
                            width: "812",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              fill: "#E5E5E5",
                              height: "900",
                              width: "812"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              fill: "white",
                              height: "900",
                              width: "1440"
                            }
                          }),
                          _c("rect", {
                            attrs: {
                              fill: "#F0F8FC",
                              height: "900",
                              width: "812"
                            }
                          }),
                          _c("circle", {
                            attrs: {
                              cx: "148.5",
                              cy: "224.5",
                              fill: "#DCEEF8",
                              r: "10.5"
                            }
                          }),
                          _c("circle", {
                            attrs: {
                              cx: "588",
                              cy: "802",
                              fill: "#DCEEF8",
                              r: "13"
                            }
                          }),
                          _c("ellipse", {
                            attrs: {
                              cx: "641",
                              cy: "161.5",
                              fill: "#DCEEF8",
                              rx: "29",
                              ry: "29.5"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M386.269 231.827C204.087 231.827 56.3986 379.566 56.3986 561.812C56.3986 630.488 77.3705 694.264 113.257 747.081H659.281C695.168 694.264 716.14 630.488 716.14 561.812C716.14 379.566 568.452 231.827 386.269 231.827Z",
                              fill: "#DCEEF8",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M698.949 428.328C681.531 178.818 361.001 62.47 203.898 231.827C64.087 382.544 -4.09991 595.139 115.794 747.076L477.809 747.076C484.102 735.431 489.982 723.631 495.531 712.009C548.944 600.147 716.367 677.837 698.949 428.328Z",
                              fill: "#DCEEF8"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M361.041 636.875L472.315 600.72L496.727 675.854L385.453 712.009L361.041 636.875Z",
                              fill: "#13BB84"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M561.838 658.808L555.143 746.737H407.919L409.48 704.403L434.597 696.025L487.713 634.804H555.143L561.838 658.808Z",
                              fill: "#171822",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask0",
                                height: "51",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "74",
                                x: "447",
                                y: "278"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M518.436 325.177C521.171 308.322 521.171 299.366 518.436 298.309C520.834 293.982 521.296 289.982 519.821 286.31C517.609 280.801 511.96 275.445 468.108 279.503C455.675 281.472 449.054 287.552 448.248 297.743C447.441 307.933 448.005 318.163 449.94 328.434H518.436V325.177Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask0)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M518.436 325.177C521.171 308.322 521.171 299.366 518.436 298.309C520.834 293.982 521.296 289.982 519.821 286.31C517.609 280.801 511.96 275.445 468.108 279.503C455.675 281.472 449.054 287.552 448.248 297.743C447.441 307.933 448.005 318.163 449.94 328.434H518.436V325.177Z",
                                fill: "#2C1919"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M452.827 328.439L518.436 327.242C521.988 307.913 521.988 298.269 518.436 298.309C514.27 304.6 503.203 306.697 485.234 304.6C458.282 301.454 449.363 304.539 451.703 325.177C453.263 338.936 453.638 340.023 452.827 328.439Z",
                                fill: "#160F0F",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M516.069 326.75C518.548 324.653 520.544 324.653 522.059 326.75C524.331 329.895 517.936 342.461 515.441 342.461C513.777 342.461 513.987 337.224 516.069 326.75Z",
                              fill: "#FF927A",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M452.307 326.75C449.828 324.653 447.831 324.653 446.317 326.75C444.045 329.895 450.44 342.461 452.935 342.461C454.598 342.461 454.389 337.224 452.307 326.75Z",
                              fill: "#FF927A",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask1",
                                height: "118",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "66",
                                x: "451",
                                y: "304"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M502.886 377.987C511.675 373.615 516.069 354.826 516.069 321.62V304.6C511.551 307.9 506.297 309.55 500.307 309.55C494.191 309.55 457.17 303.562 454.043 310.961C450.917 318.36 448.635 365.454 462.934 377.548V405.502C465.076 415.466 471.683 420.886 482.756 421.76C499.366 423.071 501.492 411.219 502.886 405.502C503.815 401.69 503.815 392.518 502.886 377.987Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask1)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M502.886 377.987C511.675 373.615 516.069 354.826 516.069 321.62V304.6C511.551 307.9 506.297 309.55 500.307 309.55C494.191 309.55 457.17 303.562 454.043 310.961C450.917 318.36 448.635 365.454 462.934 377.548V405.502C465.076 415.466 471.683 420.886 482.756 421.76C499.366 423.071 501.492 411.219 502.886 405.502C503.815 401.69 503.815 392.518 502.886 377.987Z",
                                fill: "#FF927A"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M488.095 330.368V349.224C486.885 350.485 485.604 351.116 484.253 351.116C482.903 351.116 481.596 350.485 480.335 349.224",
                                stroke: "black",
                                "stroke-width": "1.2"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M474.161 336.847C473.048 336.847 472.146 335.565 472.146 333.984C472.146 332.402 473.048 331.121 474.161 331.121C475.274 331.121 476.176 332.402 476.176 333.984C476.176 335.565 475.274 336.847 474.161 336.847Z",
                                fill: "black"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M498.336 336.847C497.223 336.847 496.321 335.565 496.321 333.984C496.321 332.402 497.223 331.121 498.336 331.121C499.449 331.121 500.351 332.402 500.351 333.984C500.351 335.565 499.449 336.847 498.336 336.847Z",
                                fill: "black"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M504.212 324.532C500.092 322.915 496.119 322.915 492.293 324.532",
                                stroke: "black",
                                "stroke-width": "1.2"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M468.117 324.532C472.237 322.915 476.21 322.915 480.036 324.532",
                                stroke: "black",
                                "stroke-width": "1.2"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M504.58 376.466C490.893 387.627 476.448 387.627 461.243 376.466L462.224 384.136C474.044 395.448 489.005 401.104 507.106 401.104L504.58 376.466Z",
                                fill: "#E1806A",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask2",
                                height: "49",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "65",
                                x: "451",
                                y: "338"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M515.441 338.646C512.534 352.805 508.24 359.295 502.556 358.115C494.032 356.347 483.164 351.491 471.14 357.337C459.117 363.184 456.677 362.926 451.834 338.646C452.136 352.994 454.255 363.978 458.192 371.597C464.097 383.025 479.36 391.133 497.047 384.257C508.838 379.674 514.97 364.47 515.441 338.646Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask2)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M515.441 338.646C512.534 352.805 508.24 359.295 502.556 358.115C494.032 356.347 483.164 351.491 471.14 357.337C459.117 363.184 456.677 362.926 451.834 338.646C452.136 352.994 454.255 363.978 458.192 371.597C464.097 383.025 479.36 391.133 497.047 384.257C508.838 379.674 514.97 364.47 515.441 338.646Z",
                                fill: "#2C1919"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M515.441 336.847C511.49 368.991 500.27 384.361 481.779 382.958C470.603 382.11 461.891 377.37 455.644 368.739C458.598 375.199 462.087 380.055 466.112 383.308C472.149 388.187 494.09 396.016 504.212 384.216C510.959 376.35 514.702 366.047 515.441 353.306L516.069 338.646L515.441 336.847Z",
                                fill: "#160F0F",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M492.124 363.294H474.684C477.42 368.578 480.554 371.093 484.085 370.838C487.616 370.583 490.296 368.068 492.124 363.294Z",
                              fill: "#EEECEA",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M498.539 342.225C493.9 342.225 490.139 338.464 490.139 333.825C490.139 329.185 493.9 325.425 498.539 325.425C503.178 325.425 506.939 329.185 506.939 333.825C506.939 338.464 503.178 342.225 498.539 342.225Z",
                              stroke: "black",
                              "stroke-width": "1.2"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M474.539 342.225C469.9 342.225 466.139 338.464 466.139 333.825C466.139 329.185 469.9 325.425 474.539 325.425C479.178 325.425 482.939 329.185 482.939 333.825C482.939 338.464 479.178 342.225 474.539 342.225Z",
                              stroke: "black",
                              "stroke-width": "1.2"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M466.139 333.237L451.269 325.177M490.139 333.237H482.939H490.139Z",
                              stroke: "black",
                              "stroke-width": "1.2"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M506.469 333.237L517.537 323.926",
                              stroke: "black",
                              "stroke-width": "1.2"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask3",
                                height: "39",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "85",
                                x: "673",
                                y: "505"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M673.678 522.915C683.975 512.04 691.801 506.603 697.155 506.603C702.51 506.603 712.454 506.09 726.987 505.065C727.872 508.015 727.078 510.24 724.604 511.74C744.012 509.992 754.65 509.961 756.518 511.647C759.32 514.176 756.279 515.644 754.009 516.74C755.702 518.426 754.361 520.175 749.988 521.987C750.886 524.539 747.671 526.417 740.341 527.622C740.381 529.642 737.768 531.401 732.502 532.899C724.604 535.146 701.821 539.585 694.579 539.585C689.75 539.585 684.217 540.948 677.978 543.672L673.678 522.915Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask3)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M673.678 522.915C683.975 512.04 691.801 506.603 697.155 506.603C702.51 506.603 712.454 506.09 726.987 505.065C727.872 508.015 727.078 510.24 724.604 511.74C744.012 509.992 754.65 509.961 756.518 511.647C759.32 514.176 756.279 515.644 754.009 516.74C755.702 518.426 754.361 520.175 749.988 521.987C750.886 524.539 747.671 526.417 740.341 527.622C740.381 529.642 737.768 531.401 732.502 532.899C724.604 535.146 701.821 539.585 694.579 539.585C689.75 539.585 684.217 540.948 677.978 543.672L673.678 522.915Z",
                                fill: "#FF927A"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M740.341 527.622C740.448 526.345 737.958 526.345 732.873 527.622C725.245 529.539 715.885 529.941 713.095 529.74C710.305 529.539 710.179 522.358 689.531 523.363M749.988 521.987C749.094 520.386 739.932 520.812 722.502 523.267M754.009 516.74C750.61 515.214 739.926 515.426 721.958 517.375M721.958 515.735C723.569 519.787 723.075 523.527 720.474 526.957M724.604 511.74C720.094 512.489 716.043 512.863 712.45 512.863",
                                stroke: "#7D1C1C"
                              }
                            })
                          ]),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask4",
                                height: "310",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "328",
                                x: "354",
                                y: "388"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M462.529 388.109C445.981 395.281 435.846 399.47 432.125 400.674C426.543 402.479 382.597 403.606 372.526 440.826C362.455 478.045 346.065 568.136 358.479 592.972C366.754 609.529 380.713 635.058 400.355 669.559V697.149H497.928L504.588 642.602L528.417 694.386L574.744 676.789C556.445 616.545 547.295 582.337 547.295 574.168C547.295 565.998 548.64 548.739 551.33 522.392C566.758 553.181 580.451 569.563 592.41 571.537C604.369 573.512 634.076 568.334 681.531 556.004L673.569 515.629L603.521 525.665C587.465 461.877 571.525 426.406 555.7 419.253C539.875 412.1 526.976 406.538 517.002 402.567L503.665 395.108C498.862 402.414 493.504 406.066 487.59 406.066C481.677 406.066 473.323 400.08 462.529 388.109ZM405.323 540.911C411.847 554.344 411.847 570.443 405.323 589.207L399.206 570.266L405.323 540.911Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask4)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M462.529 388.109C445.981 395.281 435.846 399.47 432.125 400.674C426.543 402.479 382.597 403.606 372.526 440.826C362.455 478.045 346.065 568.136 358.479 592.972C366.754 609.529 380.713 635.058 400.355 669.559V697.149H497.928L504.588 642.602L528.417 694.386L574.744 676.789C556.445 616.545 547.295 582.337 547.295 574.168C547.295 565.998 548.64 548.739 551.33 522.392C566.758 553.181 580.451 569.563 592.41 571.537C604.369 573.512 634.076 568.334 681.531 556.004L673.569 515.629L603.521 525.665C587.465 461.877 571.525 426.406 555.7 419.253C539.875 412.1 526.976 406.538 517.002 402.567L503.665 395.108C498.862 402.414 493.504 406.066 487.59 406.066C481.677 406.066 473.323 400.08 462.529 388.109ZM405.323 540.911C411.847 554.344 411.847 570.443 405.323 589.207L399.206 570.266L405.323 540.911Z",
                                fill: "white"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M504.716 393.582L540.071 423.957L521.034 430.347L536.289 439.471L500.737 537.094L504.716 393.582Z",
                                fill: "#1B9AAA",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M462.243 387.786L449.606 392.394L430.136 422.61L451.641 427.619L436.431 442.815L500.737 537.328L464.616 400.423L462.243 387.786Z",
                                fill: "#1B9AAA",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c(
                              "mask",
                              {
                                attrs: {
                                  id: "mask5",
                                  height: "149",
                                  "mask-type": "alpha",
                                  maskUnits: "userSpaceOnUse",
                                  width: "51",
                                  x: "460",
                                  y: "389"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M504.732 395.2C513.198 436.167 511.866 483.465 500.737 537.094C477.203 495.632 463.839 446.298 460.645 389.094L487.848 402.517L504.732 395.2Z",
                                    fill: "white"
                                  }
                                })
                              ]
                            ),
                            _c("g", { attrs: { mask: "url(#mask5)" } }, [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M504.732 395.2C513.198 436.167 511.866 483.465 500.737 537.094C477.203 495.632 463.839 446.298 460.645 389.094L487.848 402.517L504.732 395.2Z",
                                  fill: "#CCE6F4"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  "clip-rule": "evenodd",
                                  d:
                                    "M499.096 417.709L492.436 428.328H486.642L476.329 417.088L483.78 406.513H497.434L499.096 417.709Z",
                                  fill: "#FF4848",
                                  "fill-rule": "evenodd"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  "clip-rule": "evenodd",
                                  d:
                                    "M492.436 428.328L509.038 506.058C509.038 523.042 506.271 534.66 500.737 540.911L479.414 491.584L486.642 428.328H492.436Z",
                                  fill: "#FF4848",
                                  "fill-rule": "evenodd"
                                }
                              })
                            ]),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M504.716 393.582L509.037 426.515L487.524 406.755L465.924 427.619C459.788 412.909 458.228 399.128 461.242 386.278C464.256 373.428 478.747 375.863 504.716 393.582Z",
                                fill: "#7FBCF4",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M500.737 535.34C500.737 536.509 502.14 573.011 504.948 644.846",
                                stroke: "#E7EEFF"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M492.031 547.109C490.098 547.109 488.531 545.542 488.531 543.609C488.531 541.676 490.098 540.109 492.031 540.109C493.964 540.109 495.531 541.676 495.531 543.609C495.531 545.542 493.964 547.109 492.031 547.109Z",
                                fill: "#666E78"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M492.031 587.109C490.098 587.109 488.531 585.542 488.531 583.609C488.531 581.676 490.098 580.109 492.031 580.109C493.964 580.109 495.531 581.676 495.531 583.609C495.531 585.542 493.964 587.109 492.031 587.109Z",
                                fill: "#666E78"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M405.323 589.207L434.597 649.897L399.206 670.77V698.109H426.262L439.311 647.577L405.323 589.207Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M399.091 570.455C402.11 579.612 404.423 586.215 406.03 590.263C406.895 592.441 414.93 609.34 430.136 640.958C433.806 610.045 427.166 566.171 410.216 509.336L404.711 548.173L399.091 570.455Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M361.041 636.875L472.315 600.72L496.727 675.854L385.453 712.009L361.041 636.875Z",
                                fill: "#13BB84"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M553.842 498.253L548.944 549.012L581.134 574.445L553.842 498.253Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M603.521 525.665C600.618 528.283 598.871 532.316 598.279 537.766C604.64 530.075 612.353 525.095 621.419 522.825L605.38 523.781L603.521 525.665ZM400.164 569.812C392.427 569.276 386.062 570.767 381.069 574.283C394.367 579.298 402.582 584.356 405.715 589.459L400.164 569.812Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M418.267 615.948L434.597 649.897L400.585 670.086L377.881 629.432L418.267 615.948Z",
                                fill: "white",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M360.192 496.077C383.26 533.017 393.566 558.843 391.111 573.553C389.844 581.14 377.708 585.184 354.703 585.684L346.235 556.953L360.192 496.077Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M430.507 651.814L407.919 665.095C413.311 672.325 416.281 678.185 416.829 682.674C417.652 689.409 425.468 701.764 428.45 701.764C431.433 701.764 455.455 700.877 457.322 699.013C459.189 697.149 462.058 690.649 460.623 687.962C457.81 689.064 455.802 688.591 454.599 686.543C453.396 684.495 452.036 681.787 450.521 678.421C453.584 681.707 456.952 683.125 460.623 682.674C462.101 682.456 461.068 680.335 457.525 676.312C452.211 670.276 452.211 663.207 444.8 660.396C439.86 658.522 435.095 655.662 430.507 651.814Z",
                              fill: "#FF927A",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M467.636 679.284L447.216 618.058M485.636 672.284L465.216 611.058L485.636 672.284ZM476.636 676.284L456.216 615.058L476.636 676.284Z",
                              stroke: "white"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask6",
                                height: "151",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "150",
                                x: "199",
                                y: "596"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M203.053 663.652C197.462 693.095 199.403 720.778 208.878 746.701H346.855C349.365 709.913 348.135 682.23 343.165 663.652C331.446 619.845 327.781 597.344 332.172 596.149L224.679 601.708C215.854 613.561 208.645 634.209 203.053 663.652Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask6)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M203.053 663.652C197.462 693.095 199.403 720.778 208.878 746.701H346.855C349.365 709.913 348.135 682.23 343.165 663.652C331.446 619.845 327.781 597.344 332.172 596.149L224.679 601.708C215.854 613.561 208.645 634.209 203.053 663.652Z",
                                fill: "#1B9AAA"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M330.782 605.988C313.559 614.37 298.013 639.913 284.145 682.617C263.343 746.673 328.662 823.008 318.663 854.719C312.523 874.193 281.947 876.919 226.937 862.897C229.294 835.51 226.814 813.177 219.496 795.897C208.52 769.978 192.236 714.023 200.378 676.837C205.806 652.046 208.516 627.385 208.508 602.852L258.793 608.957L330.782 605.988Z",
                                fill: "#198997",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask7",
                                height: "255",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "206",
                                x: "181",
                                y: "430"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M280.004 430.117L258.007 432.714C220.682 442.784 199.586 449.177 194.719 451.893C187.418 455.966 177.896 466.497 183.175 489.325C187.992 510.156 198.922 546.808 212.881 571.101L212.877 571.112L213.22 571.687C214.45 573.803 215.704 575.821 216.978 577.724C203.257 623.411 195.073 659.051 192.426 684.646L253.598 684.537L248.873 611.09C277.854 618.99 303.576 618.378 326.04 609.254C329.992 652.101 331.967 673.525 331.967 673.525L353.067 676.493L337.887 579.892C342.07 580.093 345.984 580.181 349.628 580.156L350.582 580.159C351.127 580.159 351.66 580.146 352.182 580.12C377.665 579.553 389.014 573.07 386.232 560.67C381.346 538.892 343.191 460.018 335.003 449.357C326.815 438.696 316.024 430.117 280.004 430.117Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask7)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M280.004 430.117L258.007 432.714C220.682 442.784 199.586 449.177 194.719 451.893C187.418 455.966 177.896 466.497 183.175 489.325C187.992 510.156 198.922 546.808 212.881 571.101L212.877 571.112L213.22 571.687C214.45 573.803 215.704 575.821 216.978 577.724C203.257 623.411 195.073 659.051 192.426 684.646L253.598 684.537L248.873 611.09C277.854 618.99 303.576 618.378 326.04 609.254C329.992 652.101 331.967 673.525 331.967 673.525L353.067 676.493L337.887 579.892C342.07 580.093 345.984 580.181 349.628 580.156L350.582 580.159C351.127 580.159 351.66 580.146 352.182 580.12C377.665 579.553 389.014 573.07 386.232 560.67C381.346 538.892 343.191 460.018 335.003 449.357C326.815 438.696 316.024 430.117 280.004 430.117Z",
                                fill: "white"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M326.518 578.881C325.313 593.329 325.154 603.453 326.04 609.254C320.417 617.184 307.969 621.03 288.697 620.793C269.426 620.556 256.151 617.321 248.873 611.09L250.212 600.466C266.273 599.391 279.804 597.329 290.806 594.278C301.808 591.228 313.712 586.095 326.518 578.881Z",
                                fill: "#EFA12C",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M267.835 427.636C280.162 429.601 288.293 434.087 292.226 441.096C298.127 451.609 334.584 503.902 335.628 518.087C335.893 521.689 334.621 531.099 331.81 546.315C327.887 547.251 324.791 547.759 322.521 547.84C318.598 547.979 312.26 547.891 303.506 547.576V547.719C291.613 546.71 283.549 545.766 279.311 544.887C270.807 543.123 265.499 540.113 262.71 538.714C262.12 538.418 261.134 538.264 259.751 538.253C258.288 538.038 256.458 538.492 254.262 539.615C253.786 530.654 253.189 524.771 252.47 521.966C247.129 501.124 237.168 452.213 244.37 438.617C249.171 429.553 256.992 425.893 267.835 427.636Z",
                                fill: "#EFA12C",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M251.989 600.338C238.624 598.999 226.954 591.461 216.978 577.724C211.079 593.724 206.719 608.084 203.898 620.803L251.989 600.338Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M247.752 545.139L226.694 488.354L250.044 542.925L247.752 545.139Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M325.76 589.237L326.518 578.881L339.209 580.159L325.76 589.237Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M353.39 538.081L330.782 480.944C340.39 505.943 345.194 520.04 345.194 523.236C345.194 526.431 343.199 533.878 339.209 545.575L353.39 538.081Z",
                                fill: "#E7EEFF",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c("path", {
                            attrs: {
                              d:
                                "M266.428 335.413C255.487 335.413 246.618 326.461 246.618 315.419C246.618 304.376 255.487 295.425 266.428 295.425C277.369 295.425 286.238 304.376 286.238 315.419C286.238 326.461 277.369 335.413 266.428 335.413Z",
                              fill: "#7D1C1C"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M295.146 393.409C302.937 374.081 305.229 355.583 302.02 337.913C297.207 311.408 261.565 311.396 245.009 320.201C228.452 329.005 221.692 356.996 238.605 393.409C249.88 417.684 268.727 417.684 295.146 393.409Z",
                              fill: "#7D1C1C",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M263.296 295.875C249.814 297.423 244.255 305.293 246.619 319.484C229.508 325.132 224.225 341.263 230.77 367.877",
                              stroke: "#7D1C1C",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M286.238 318.351C274.335 312.778 261.129 313.156 246.619 319.484",
                              stroke: "white",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c(
                            "mask",
                            {
                              attrs: {
                                id: "mask8",
                                height: "118",
                                "mask-type": "alpha",
                                maskUnits: "userSpaceOnUse",
                                width: "75",
                                x: "229",
                                y: "344"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M229.72 369.49C230.68 367.187 232.926 366.753 236.459 368.189L236.692 359.926C247.339 359.449 256.301 359.449 263.934 357.403C270.882 355.54 279.1 351.323 288.588 344.752C291.155 349.867 293.758 352.964 296.396 354.043V367.708C300.178 366.032 302.507 366.626 303.384 369.49C304.699 373.786 299.779 384.456 296.921 385.696C295.122 396.87 291.502 405.97 286.059 412.994C284.784 426.926 284.784 436.091 286.059 440.488C287.973 447.084 290.296 458.381 275.459 461.39C260.622 464.399 248.221 445.784 247.339 440.488C246.75 436.958 246.75 427.793 247.339 412.994C243.201 408.269 239.652 399.17 236.692 385.696C235.599 385.346 234.487 384.47 233.359 383.067C230.881 379.987 228.28 372.946 229.72 369.49Z",
                                  fill: "white"
                                }
                              })
                            ]
                          ),
                          _c("g", { attrs: { mask: "url(#mask8)" } }, [
                            _c("path", {
                              attrs: {
                                d:
                                  "M229.72 369.49C230.68 367.187 232.926 366.753 236.459 368.189L236.692 359.926C247.339 359.449 256.301 359.449 263.934 357.403C270.882 355.54 279.1 351.323 288.588 344.752C291.155 349.867 293.758 352.964 296.396 354.043V367.708C300.178 366.032 302.507 366.626 303.384 369.49C304.699 373.786 299.779 384.456 296.921 385.696C295.122 396.87 291.502 405.97 286.059 412.994C284.784 426.926 284.784 436.091 286.059 440.488C287.973 447.084 290.296 458.381 275.459 461.39C260.622 464.399 248.221 445.784 247.339 440.488C246.75 436.958 246.75 427.793 247.339 412.994C243.201 408.269 239.652 399.17 236.692 385.696C235.599 385.346 234.487 384.47 233.359 383.067C230.881 379.987 228.28 372.946 229.72 369.49Z",
                                fill: "#FFAE9C"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M269.572 373.56V393.929C268.495 395.055 267.356 395.619 266.155 395.619C264.953 395.619 263.791 395.055 262.669 393.929",
                                stroke: "black",
                                "stroke-width": "1.326"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M257.178 378.17C256.188 378.17 255.386 377.025 255.386 375.613C255.386 374.201 256.188 373.056 257.178 373.056C258.168 373.056 258.97 374.201 258.97 375.613C258.97 377.025 258.168 378.17 257.178 378.17Z",
                                fill: "black"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M279.847 378.17C278.857 378.17 278.055 377.025 278.055 375.613C278.055 374.201 278.857 373.056 279.847 373.056C280.837 373.056 281.639 374.201 281.639 375.613C281.639 377.025 280.837 378.17 279.847 378.17Z",
                                fill: "black"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M285.073 368.348C281.409 366.903 277.875 366.903 274.471 368.348",
                                stroke: "black",
                                "stroke-width": "1.326"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M251.802 368.348C255.466 366.903 259 366.903 262.404 368.348",
                                stroke: "black",
                                "stroke-width": "1.326"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M274.321 402.966C270.81 403.591 268.141 403.903 266.314 403.903C264.487 403.903 261.718 403.591 258.007 402.966C260.567 407.076 263.499 409.032 266.802 408.833C270.105 408.635 272.611 406.679 274.321 402.966Z",
                                fill: "#EEECEA",
                                "fill-rule": "evenodd"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "clip-rule": "evenodd",
                                d:
                                  "M287.564 411.425C273.565 426.159 259.632 426.159 245.764 411.425L246.423 419.075C251.036 424.857 256.84 429.211 263.834 432.137C270.829 435.063 278.739 437.223 287.564 438.617V411.425Z",
                                fill: "#F99B86",
                                "fill-rule": "evenodd"
                              }
                            })
                          ]),
                          _c("path", {
                            attrs: {
                              d:
                                "M299.057 385.984C297.77 385.984 296.726 384.931 296.726 383.632C296.726 382.333 297.77 381.28 299.057 381.28C300.344 381.28 301.388 382.333 301.388 383.632C301.388 384.931 300.344 385.984 299.057 385.984Z",
                              fill: "#198997"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M234.967 385.984C233.68 385.984 232.636 384.931 232.636 383.632C232.636 382.333 233.68 381.28 234.967 381.28C236.254 381.28 237.298 382.333 237.298 383.632C237.298 384.931 236.254 385.984 234.967 385.984Z",
                              fill: "#198997"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M288.684 343.49C274.758 358.637 257.427 364.115 236.692 359.926",
                              stroke: "#7D1C1C",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M339.208 545.576C340.427 532.992 341.597 525.798 342.718 523.994C344.4 521.289 350.397 516.284 351.79 512.545C355.528 513.283 356.364 515.464 354.297 519.089C351.196 524.525 348.81 527.655 350.3 528.376C355.296 522.819 358.615 519.881 360.256 519.561C362.718 519.08 374.006 517.972 374.727 519.561C375.208 520.62 374.533 521.845 372.702 523.236C374.065 523.367 375.011 524.201 375.541 525.74C376.337 528.047 374.798 530.024 371.952 531.677C374.518 531.459 375.622 532.057 375.264 533.471C374.727 535.593 367.402 537.491 366.641 540.666C359.958 539.248 354.029 539.553 348.855 541.58C347.252 542.208 344.036 543.54 339.208 545.576Z",
                              fill: "#FFAE9C",
                              "fill-rule": "evenodd"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M372.702 523.237L362.476 525.062L357.332 528.731",
                              stroke: "#7D1C1C",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M375.541 525.984L365.017 528.731L360.192 532.273",
                              stroke: "#7D1C1C",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d:
                                "M372.503 531.497C369.412 532.014 366.545 533.694 363.902 536.537",
                              stroke: "#7D1C1C",
                              "stroke-width": "1.326"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              "clip-rule": "evenodd",
                              d:
                                "M303.506 547.576V573.553L280.604 563.119H254.101L247.752 545.138C252.488 540.056 256.57 537.718 259.999 538.127C265.142 538.739 275.622 544.488 282.894 545.138C287.743 545.572 294.613 546.384 303.506 547.576Z",
                              fill: "#FFAE9C",
                              "fill-rule": "evenodd"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center auth-bg reg-right-side",
                  attrs: { lg: "6", xl: "5" }
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "px-lg-0 px-xl-0 mx-auto right-col",
                      attrs: { cols: "12", xl: "11", lg: "12", md: "9" }
                    },
                    [
                      _c("h1", { staticClass: "txt-black" }, [
                        _vm._v("Hello!")
                      ]),
                      _c("h2", { staticClass: "txt-black" }, [
                        _vm._v("Create your account")
                      ]),
                      _c(
                        "form-wizard",
                        {
                          staticClass: "shadow-none remove_border form-reg",
                          attrs: {
                            subtitle: null,
                            title: null,
                            "back-button-text": "Previous",
                            color: "#1B9AAA",
                            shape: "square"
                          },
                          on: { "on-complete": _vm.formSubmitted },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "footer",
                                fn: function(props) {
                                  return [
                                    !props.isLastStep
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "bg-black",
                                            attrs: {
                                              block: "",
                                              variant: "dark"
                                            },
                                            on: {
                                              click: function($event) {
                                                return props.nextTab()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Next "),
                                            _c("feather-icon", {
                                              staticClass: "mr-50",
                                              attrs: {
                                                icon: "ChevronRightIcon"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c(
                                          "b-overlay",
                                          {
                                            attrs: {
                                              opacity: 0.5,
                                              show: _vm.loading,
                                              rounded: "sm"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "bg-black",
                                                attrs: {
                                                  disabled: _vm.loading,
                                                  block: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return props.nextTab()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.isLastStep
                                                        ? "Create Account"
                                                        : "Next"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2747772589
                          )
                        },
                        [
                          _c(
                            "tab-content",
                            {
                              attrs: {
                                "before-change": _vm.validatePersonalDetails,
                                title: "Personal Details"
                              }
                            },
                            [
                              _c(
                                "validation-observer",
                                {
                                  ref: "personalDetails",
                                  attrs: { tag: "form" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Name",
                                                "label-for": "name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "name",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              placeholder:
                                                                "Enter full name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.payload
                                                                  .name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.payload,
                                                                  "name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "payload.name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2284937332
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Email",
                                                "label-for": "email"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "email",
                                                  rules: "required|email"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "email",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              placeholder:
                                                                "mymail@example.com",
                                                              type: "email"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.payload
                                                                  .email,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.payload,
                                                                  "email",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "payload.email"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2770673872
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "label",
                                            [
                                              _vm._v("Password "),
                                              _c("feather-icon", {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value:
                                                      "Your password must be at least 8\n" +
                                                      "characters lenghts, and it must include:",
                                                    expression:
                                                      "'Your password must be at least 8\\n'+\n'characters lenghts, and it must include:'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "text-primary ml-50",
                                                attrs: {
                                                  icon: "InfoIcon",
                                                  size: "12"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Password",
                                              rules: "required|password",
                                              vid: "Password"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "password",
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                          placeholder:
                                                            "Enter Your Password",
                                                          type: "password"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.payload
                                                              .password,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.payload,
                                                              "password",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "payload.password"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4032302655
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "tab-content",
                            {
                              attrs: {
                                "before-change":
                                  _vm.validateOrganizationDetails,
                                title: "Organisation Details"
                              }
                            },
                            [
                              _c(
                                "validation-observer",
                                {
                                  ref: "organizationDetails",
                                  attrs: { tag: "form" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Organization name",
                                                "label-for": "organization-name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Organization name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "organization-name",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              placeholder:
                                                                "Enter"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.payload
                                                                  .organization_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.payload,
                                                                  "organization_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "payload.organization_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1317058642
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Organization type",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            state:
                                                              errors.length > 0
                                                                ? false
                                                                : null,
                                                            label:
                                                              "Organization type",
                                                            "label-for":
                                                              "organization_type"
                                                          }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              id:
                                                                "organization_type",
                                                              clearable: false,
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.organization_types,
                                                              selectable: function(
                                                                option
                                                              ) {
                                                                return !option.value.includes(
                                                                  "select_value"
                                                                )
                                                              },
                                                              label: "text",
                                                              placeholder:
                                                                "Select"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selected_organization_type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selected_organization_type = $$v
                                                              },
                                                              expression:
                                                                "selected_organization_type"
                                                            }
                                                          }),
                                                          _c(
                                                            "b-form-invalid-feedback",
                                                            {
                                                              attrs: {
                                                                state:
                                                                  errors.length >
                                                                  0
                                                                    ? false
                                                                    : null
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    errors[0]
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2480968667
                                            )
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Address",
                                                "label-for": "address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "address",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              placeholder:
                                                                "Enter"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.payload
                                                                  .address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.payload,
                                                                  "address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "payload.address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2083743296
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "City",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            state:
                                                              errors.length > 0
                                                                ? false
                                                                : null,
                                                            label: "City",
                                                            "label-for": "city"
                                                          }
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              id: "city",
                                                              clearable: false,
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.cities,
                                                              selectable: function(
                                                                option
                                                              ) {
                                                                return !option.value.includes(
                                                                  "select_value"
                                                                )
                                                              },
                                                              placeholder:
                                                                "Select"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selected_city,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selected_city = $$v
                                                              },
                                                              expression:
                                                                "selected_city"
                                                            }
                                                          }),
                                                          _c(
                                                            "b-form-invalid-feedback",
                                                            {
                                                              attrs: {
                                                                state:
                                                                  errors.length >
                                                                  0
                                                                    ? false
                                                                    : null
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    errors[0]
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3541179559
                                            )
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Postal Code",
                                                "label-for": "postal_code"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Postal Code",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "postal_code",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              placeholder:
                                                                "Enter"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.payload
                                                                  .postal_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.payload,
                                                                  "postal_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "payload.postal_code"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2992379808
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _vm._v("Already have an account? "),
                          _c(
                            "b-button",
                            {
                              staticClass: "pl-0",
                              attrs: { variant: "link" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/")
                                }
                              }
                            },
                            [_vm._v("Sign In")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.is_submitted
        ? _c(
            "b-row",
            { staticClass: "auth-inner m-0" },
            [
              _c(
                "b-col",
                {
                  staticClass: " d-flex align-items-center reg_success pt-5",
                  staticStyle: { "background-color": "white" },
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-lg-flex text-center align-items-center justify-content-center px-5"
                    },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center mt-5 pt-5"
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mt-5 pt-5",
                              attrs: { cols: "12", md: "8" }
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "font-weight-700 mt-5" },
                                [_vm._v("Congratulations!")]
                              ),
                              _c("p", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  "Your account has been successfully created. Start with your Hyre journey."
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "bg-black mt-1",
                                  attrs: { block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push("/")
                                    }
                                  }
                                },
                                [_vm._v("Discover Hyre")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }